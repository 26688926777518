import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import { PlannedPmDoneOnSchedulePieChart } from "./PlannedPmDoneOnSchedulePieChart";
import SelectableChart from "../../../../components/SelectableChart";
import PercentageOfSystemUptimePieChart from "./PercentageOfSystemUptimePieChart";
import RepairTimePerformancePieChart from "./RepairTimePerformancePieChart";

export default function ServiceStandardsAnalytic() {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between mt-2 col-span-2">
        <DateFilter />
      </div>
      <div className="col-span-2">
        <SelectableChart chartId="admc-planned-pm-done-on-schedule-pie-chart">
          <PlannedPmDoneOnSchedulePieChart />
        </SelectableChart>
      </div>
      <div className="col-span-2">
        <SelectableChart chartId="admc-system-uptime-pie-chart">
          <PercentageOfSystemUptimePieChart />
        </SelectableChart>
      </div>
      <div className="col-span-2">
        <SelectableChart chartId="admc-repair-time-performance-pie-chart">
          <RepairTimePerformancePieChart />
        </SelectableChart>
      </div>
    </div>
  );
}
