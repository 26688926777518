import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useParams, useSearchParams } from "react-router-dom";
import { useCreateWorkRequestMutation } from "../../../../redux/slices/WorkRequest/WorkRequestApi";
import {
  useLocationControllerGetWorkRequestLocations,
  useLocationControllerPublicShowLocation,
} from "../../../../api/location/location";
import {
  useWRUserInfoActions,
  useWRUserInfoStore,
} from "../../../../stores/workRequest/useWRUserInfoStore";
import { useEffect, useState } from "react";
import PublicPageWrapper from "../../../../components/PublicPageWrapper";
import AppLogoText from "../../../../components/AppLogoText";
import {
  CheckCircleOutlineRounded,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import AppButton from "../../../../components/AppButton";
import AppCameraField from "../../../../components/AppCameraField";
import AppTextAreaField from "../../../../components/AppTextAreaField";
import AppTextField from "../../../../components/AppTextField";
import AppSelectWithDialog from "../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { publicWorkRequestLocationColumns } from "../columns/publicWorkRequestLocationColumns";
import AssetSelectionWithDialog from "../form/AssetSelectionWithDialog";
import { RouterOutputs } from "@cerev-cmms/trpc";
import { dynamicFieldSchema } from "@cerev-cmms/zod-types";
import { Checkbox } from "../../../../components/ui/checkbox";
import DynamicField from "../../../../components/DynamicField/DynamicField";

type DynamicFieldType = z.infer<typeof dynamicFieldSchema>;
type DynamicFieldValue = string | string[];

export const WorkRequestSchema = z.object({
  email: z.string().email({ message: "Please input a proper email" }),
  contactNo: z.string().min(6, { message: "Please input your contact number" }),
  location: z.string().optional(),
  title: z.string().min(1, { message: "Please add a title" }),
  description: z.string().optional(),
  name: z.string().optional(),
  department: z.string().optional(),
  photos: z
    .string()
    .array()
    .nonempty({ message: "Must have at least one photo" }),
  dbLocation: z.object({
    id: z.number(),
    name: z.string(),
  }),
  asset: z.object({
    id: z.number(),
    name: z.string(),
  }),
  // Update dynamic fields validation to match the schema
  dynamicFields: z
    .record(
      z.union([
        z.string(),
        // z.number(),
        // z.boolean(),
        z.array(z.string()),
        // z.date(),
      ])
    )
    .optional(),
});

export type WorkRequestForm = z.infer<typeof WorkRequestSchema>;

type WorkRequestSetting =
  RouterOutputs["workRequest"]["getWorkRequestSettingPublic"]["data"];

interface PublicWorkRequestFormProps {
  workRequestSetting?: WorkRequestSetting;
}

const isDynamicFieldRequired = (
  fieldId: string,
  dynamicFields?: WorkRequestSetting["dynamicFields"]
): boolean => {
  return (
    (dynamicFields as { fields: DynamicFieldType[] })?.fields?.find(
      (field: DynamicFieldType) => field.id === fieldId
    )?.required ?? false
  );
};

export default function PublicWorkRequestForm({
  workRequestSetting,
}: PublicWorkRequestFormProps) {
  // const saveUserInfoEnabled = useWrSaveUserInfoEnabled();
  const [searchParam] = useSearchParams();
  const { projectCode } = useParams();

  // Special optional search param to determine whether the project need the location to be filled or not
  const dbLocNotRequired = searchParam.get("dbLocNotRequired");

  // Asset search param if obtained from equipment
  const assetName = searchParam.get("assetName");
  const assetId = searchParam.get("assetId");

  const [createWorkRequest, { isLoading, isSuccess, isError, data, error }] =
    useCreateWorkRequestMutation();

  const { data: locationList } = useLocationControllerPublicShowLocation(
    projectCode ?? "",
    {
      query: {
        enabled: !!projectCode,
        select: (res) => res.data,
        staleTime: 1000 * 60 * 60 * 24,
      },
    }
  );

  const { saveUserInfo, resetUserInfo } = useWRUserInfoActions();
  const { email, contactNo } = useWRUserInfoStore((state) => state);
  const [rememberMe, setRememberMe] = useState(true);

  const onSubmit: SubmitHandler<WorkRequestForm> = async (data) => {
    if (!projectCode) return;

    const filesToUpload = data.photos.map((phoUrl) => fetch(phoUrl));
    const filesDataList = await Promise.all(filesToUpload);
    const filesDataBlob = await Promise.all(
      filesDataList.map((da) => da.blob())
    );
    const workReqData = new FormData();

    filesDataBlob.forEach((b) => workReqData.append("photos", b));
    workReqData.append("dbLocationId", data.dbLocation?.id.toString() ?? "");
    workReqData.append("email", data.email);
    workReqData.append("contactNo", data.contactNo);
    workReqData.append("description", data?.description ?? "");
    workReqData.append("location", data.location ?? "");
    workReqData.append("projectCode", projectCode);
    workReqData.append("name", data.name ?? "");
    workReqData.append("department", data.department ?? "");
    if (data.asset) {
      workReqData.append("assetId", data.asset?.id.toString() ?? "");
    }
    workReqData.append("title", data.title);

    // Handle dynamic fields
    if (data.dynamicFields) {
      Object.entries(data.dynamicFields).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          // Convert array to JSON string before appending
          workReqData.append(
            `dynamicFieldValues[${key}]`,
            JSON.stringify(value)
          );
        }
      });
    }

    createWorkRequest(workReqData);
  };

  const methods = useForm<WorkRequestForm>({
    resolver: zodResolver(
      z.object({
        email: z.string().email({ message: "Please input a proper email" }),
        contactNo: z
          .string()
          .optional()
          .refine(
            (val) => {
              if (workRequestSetting?.contactNoRequired === "REQUIRED")
                return !!val;

              return true;
            },
            {
              message: "Please input your contact number",
            }
          ),
        location: z
          .string()
          .optional()
          .refine(
            (val) => {
              if (workRequestSetting?.locationDetailRequired === "REQUIRED")
                return !!val;
              return true;
            },
            {
              message: "Please input your location",
            }
          ),
        title: z
          .string()
          .optional()
          .refine(
            (val) => {
              if (workRequestSetting?.subjectRequired === "REQUIRED")
                return !!val;
              return true;
            },
            {
              message: "Please add a title",
            }
          ),
        description: z
          .string()
          .optional()
          .refine(
            (val) => {
              if (workRequestSetting?.descriptionRequired === "REQUIRED")
                return !!val;
              return true;
            },
            {
              message: "Please add a description",
            }
          ),
        name: z
          .string()
          .optional()
          .refine(
            (val) => {
              if (workRequestSetting?.nameRequired === "REQUIRED") return !!val;
              return true;
            },
            {
              message: "Please add your name",
            }
          ),
        department: z
          .string()
          .optional()
          .refine(
            (val) => {
              if (workRequestSetting?.departmentRequired === "REQUIRED")
                return !!val;
              return true;
            },
            { message: "Please add your department" }
          ),
        photos: z
          .string()
          .array()
          .optional()
          .refine((val) => {
            if (workRequestSetting?.photoRequired === "REQUIRED")
              return val && val?.length > 0;
            return true;
          }),
        dbLocation: z
          .object({
            id: z.number(),
            name: z.string(),
          })
          .optional()
          .refine(
            (val) => {
              if (workRequestSetting?.locationRequired === "REQUIRED")
                return !!val;
              return true;
            },
            {
              message: "Please select a location",
            }
          ),

        asset: z
          .object({
            id: z.number(),
            name: z.string(),
          })
          .optional(),
        dynamicFields: z
          .record(
            z.string(),
            z.union([
              z.string().superRefine((val, ctx) => {
                const fieldId = (ctx.path as string[])[1]; // Gets the field ID from the validation path
                if (
                  isDynamicFieldRequired(
                    fieldId,
                    workRequestSetting?.dynamicFields
                  )
                ) {
                  if (val?.length === 0) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: "This is required",
                    });
                  }
                  return val?.length > 0;
                }
                return true;
              }),
              z.any().superRefine((val, ctx) => {
                const fieldId = (ctx.path as string[])[1];
                if (
                  isDynamicFieldRequired(
                    fieldId,
                    workRequestSetting?.dynamicFields
                  )
                ) {
                  if (val?.length === 0) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                      message: "Please select at least one option",
                    });
                  }
                  return val?.length > 0;
                }
                return true;
              }),
            ])
          )
          .optional(),
      })
    ),
    defaultValues: {
      email: "",
      contactNo: "",
      location: "",
      title: "",
      description: "",
      photos: [],
      dbLocation: undefined,
      // Only when assetId and assetName persist in searchParam, then this field will show itself
      asset:
        assetId && assetName
          ? {
              name: assetName,
              id: Number(assetId),
            }
          : undefined,
      dynamicFields: (
        (workRequestSetting?.dynamicFields as { fields: DynamicFieldType[] })
          ?.fields ?? []
      ).reduce<Record<string, DynamicFieldValue>>((acc, field) => {
        acc[field.id] =
          field.type === "select" || field.type === "multiselect" ? [] : "";
        return acc;
      }, {}),
    },
  });

  if (isSuccess) {
    return (
      <PublicPageWrapper className="justify-between items-center">
        <AppLogoText subtitle="Raise Work Request" className="w-full" />
        <div className="flex flex-col gap-4 items-center w-full">
          <CheckCircleOutlineRounded className="text-primary-900 h-32 w-32" />
          <p className="text-neutral-500">
            Work Request Form submitted successfully
          </p>
          <div className="rounded-2xl border-2 border-neutral-400 border-solid p-6 text-center gap-3 flex flex-col w-full">
            <p className="text-neutral-500">Your tracking no. is</p>
            <p className="text-primary-900 text-xl text-medium">
              {data?.trackingCode}
            </p>
          </div>
        </div>
        <AppButton
          className="w-full"
          label="Raise another"
          onClick={() => {
            location.reload();
          }}
        />
      </PublicPageWrapper>
    );
  }

  if (isError) {
    return (
      <PublicPageWrapper className="justify-between items-center">
        <AppLogoText subtitle="Raise Work Request" className="w-full" />
        <div className="flex flex-col gap-4 items-center">
          <ErrorOutlineOutlined className="text-red-400 h-32 w-32" />
          <p className="text-red-400">
            Sorry, something went wrong, please try again.
          </p>
        </div>
        <AppButton
          className="w-full"
          label="Raise again"
          onClick={() => {
            location.reload();
          }}
        />
      </PublicPageWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <PublicPageWrapper className="gap-4">
        <AppLogoText subtitle="Raise Work Request" />
        <AppTextField label="Email *" name="email" />
        {workRequestSetting?.contactNoVisibility === "VISIBLE" && (
          <AppTextField
            label={
              workRequestSetting?.contactNoRequired === "REQUIRED"
                ? "Contact No. *"
                : "Contact No. (Optional)"
            }
            name="contactNo"
          />
        )}
        {workRequestSetting?.nameVisibility === "VISIBLE" && (
          <AppTextField
            label={
              workRequestSetting?.nameRequired === "REQUIRED"
                ? "Name *"
                : "Name (Optional)"
            }
            name="name"
          />
        )}
        {workRequestSetting?.departmentVisibility === "VISIBLE" && (
          <AppTextField
            label={
              workRequestSetting?.departmentRequired === "REQUIRED"
                ? "Department *"
                : "Department (Optional)"
            }
            name="department"
          />
        )}
        {workRequestSetting?.locationVisibility === "VISIBLE" && (
          <AppSelectWithDialog
            label={
              workRequestSetting?.locationRequired === "REQUIRED"
                ? "Location *"
                : "Location (Optional)"
            }
            columns={publicWorkRequestLocationColumns}
            placeholder="Select Location"
            control={methods.control}
            defaultValue={undefined}
            name="dbLocation"
            items={locationList ?? []}
            onResultRender={(item, idx) => (
              <div className="font-medium">{item?.name ?? "-"}</div>
            )}
            onOptionsRender={(item, idx) => (
              <div className="font-medium">{item?.name ?? "-"}</div>
            )}
            dialogTitle="Select Location"
            error={!!methods.formState.errors.dbLocation?.message}
            helperText={methods.formState.errors.dbLocation?.message}
          />
        )}
        {workRequestSetting?.locationDetailVisibility === "VISIBLE" && (
          <AppTextField
            label={
              workRequestSetting?.locationDetailRequired === "REQUIRED"
                ? "Location Detail *"
                : "Location Detail (Optional)"
            }
            name="location"
          />
        )}

        <AssetSelectionWithDialog workRequestSetting={workRequestSetting} />
        {workRequestSetting?.subjectVisibility === "VISIBLE" && (
          <AppTextField
            label={
              workRequestSetting?.subjectRequired === "REQUIRED"
                ? "Subject *"
                : "Subject (Optional)"
            }
            name="title"
          />
        )}
        {workRequestSetting?.descriptionVisibility === "VISIBLE" && (
          <AppTextAreaField
            label={
              workRequestSetting?.descriptionRequired === "REQUIRED"
                ? "Description *"
                : "Description (Optional)"
            }
            name="description"
          />
        )}
        {workRequestSetting?.photoVisibility === "VISIBLE" && (
          <Controller
            control={methods.control}
            name="photos"
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <AppCameraField
                  label={
                    workRequestSetting?.photoRequired === "REQUIRED"
                      ? "Photos *"
                      : "Photos (Optional)"
                  }
                  onChange={onChange}
                  onDelete={(url) => {
                    const newSetPhotos = value.filter((v) => v !== url);
                    onChange(newSetPhotos);
                  }}
                  photos={value}
                  error={!!error}
                  helperText={
                    methods.formState.errors.photos?.message !== ""
                      ? "At least one photo required"
                      : ""
                  }
                />
              );
            }}
          />
        )}

        {((workRequestSetting?.dynamicFields as any)?.fields ?? [])?.map(
          (field: DynamicFieldType) => (
            <DynamicField
              key={field.id}
              field={field}
              control={methods.control}
              formState={methods.formState}
            />
          )
        )}

        <AppButton
          isLoading={isLoading}
          className="w-full mt-6"
          label="Submit"
          onClick={methods.handleSubmit(onSubmit)}
        />
      </PublicPageWrapper>
    </FormProvider>
  );
}
