import { useSearchParams } from "react-router-dom";
import { usePmChecklistStatusControllerGetAllPmChecklistStatusQuery } from "../../../redux/slices/OpenApi/cerevApi";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import AppDocumentUpload from "../../AppDocumentUpload";
import AppButton from "../../AppButton";
import AppCameraFieldFile from "../../AppCameraFieldFiles";
import { useGetUserDataQuery } from "../../../redux/slices/Auth/AuthApi";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useUploadAttachmentMutation } from "../../../redux/slices/Attachment/AttachmentApi";
import { SnackBarType, setOpenSnackBar } from "../../../redux/slices/RootSlice";
import useSuccess from "../../../hooks/useSuccess";
import useError from "../../../hooks/useError";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { Hash, TextCursorInput, ToggleLeft } from "lucide-react";
import { Input } from "../../ui/input";
import { Checkbox } from "../../ui/checkbox";
import { trpc } from "../../../lib/trpc";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import useAppStorage from "../../../hooks/useAppStorage";
import { updatePmChecklistValueSchema } from "@cerev-cmms/zod-types";
import { utils } from "xlsx";
import AppSelectWithDialog from "../../dialogs/AppSelectWithDialog/AppSelectWithDialog";

type PmChecklistValue = z.infer<typeof updatePmChecklistValueSchema>;

export const BOOL_TYPE = "BOOL";
export const VARCHAR_TYPE = "VARCHAR";
export const NUMBER_TYPE = "NUMBER";
export const ATTACHMENT_TYPE = "ATTACHMENT";
const MULTICHOICES_TYPE = "MULTICHOICES"; // Not in use, but declare here for typing reasons.
export const SINGLE_OPTION = "SINGLE_OPTION";
export const MULTI_OPTION = "MULTI_OPTION";

const PmChecklistFormSchema = z.object({
  pmFormat: z
    .object({
      id: z.number(),
      name: z.string(),
      pmFormatType: z.object({
        name: z.enum([
          BOOL_TYPE,
          VARCHAR_TYPE,
          NUMBER_TYPE,
          ATTACHMENT_TYPE,
          MULTICHOICES_TYPE,
          SINGLE_OPTION,
          MULTI_OPTION,
        ]),
      }),
      value: z
        .union([
          z.string().min(1, { message: "varchart is required" }),
          z.number().min(1, { message: "num is required" }),
          z.boolean({ required_error: "boolean is required" }),
          z.instanceof(File).array(),
          z.object({
            id: z.number(),
            label: z.string(),
          }),
          z.array(
            z.object({
              id: z.number(),
              label: z.string(),
            })
          ),
        ])
        .optional(),
      uploadedAttachments: z
        .object({ id: z.any(), url: z.string().optional() })
        .array()
        .optional(),
      valueId: z.number().optional(),
      formatOptions: z
        .array(
          z.object({
            id: z.number(),
            value: z.string(),
            order: z.number(),
          })
        )
        .optional(),
    })
    .array(),
});

type PmChecklistFormType = z.infer<typeof PmChecklistFormSchema>;

export default function PmChecklistFormDrawer() {
  const [searchParam, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const qc = useQueryClient();
  const pmChecklistId = searchParam.get("pmChecklistId");
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const { uploadMultipleFile } = useAppStorage();
  const utils = trpc.useUtils();

  const { data: pmChecklist, isLoading: pmChecklistIsLoading } =
    trpc.pm.getOnePmChecklist.useQuery(
      {
        pmChecklistId: Number(pmChecklistId),
      },
      {
        enabled: !!pmChecklistId,
      }
    );

  const { data: pmStatusList, isLoading: pmStatusIsLoading } =
    usePmChecklistStatusControllerGetAllPmChecklistStatusQuery(
      {
        companyId: activeComp?.id ?? 0,
      },
      {
        skip: !activeComp,
      }
    );

  const methods = useForm<PmChecklistFormType>({
    resolver: zodResolver(PmChecklistFormSchema),
  });

  const { fields, append, remove, update } = useFieldArray({
    control: methods.control,
    name: "pmFormat",
  });

  const { data: user } = useGetUserDataQuery();

  const [
    uploadAttachments,
    { isLoading: attIsLoading, isError: attIsError, error: attError },
  ] = useUploadAttachmentMutation();

  const { mutateAsync: deletePmAttachment } =
    trpc.pm.deletePmAttachment.useMutation({
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => {
            return (q.queryKey[0] as string).includes("pmChecklist");
          },
        });
      },
    });

  const { mutate: updatePmChecklist, isPending: updatePmChecklistIsPending } =
    trpc.pm.updatePmChecklist.useMutation({
      onSuccess: () => {
        toast.success("PM Checklist updated successfully");
        qc.invalidateQueries({
          predicate: (query) => {
            return (query.queryKey[0] as string).includes("pm-checklist");
          },
        });
        utils.pm.invalidate();
        utils.assets.getAssetPm.invalidate();
      },
    });

  const onSubmit: SubmitHandler<PmChecklistFormType> = async (data) => {
    if (!pmChecklist || !user || !activeProj) return;

    const uploadValues: PmChecklistValue[] = [];

    for (const d of data.pmFormat) {
      switch (d.pmFormatType.name) {
        case BOOL_TYPE:
          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistBool.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: Boolean(d.value),
            type: BOOL_TYPE,
            attachments: [],
          });
          break;
        case VARCHAR_TYPE:
          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistText.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: String(d.value || ""),
            type: VARCHAR_TYPE,
            attachments: [],
          });
          break;
        case NUMBER_TYPE:
          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistNumber.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: Number(d.value || 0),
            type: NUMBER_TYPE,
            attachments: [],
          });
          break;
        case ATTACHMENT_TYPE:
          const uploadedAtt = await uploadMultipleFile({
            files: (d.value as File[]) ?? [],
          });

          if (!uploadedAtt) return;

          uploadValues.push({
            valueId:
              pmChecklist.pmChecklistAttachment.find(
                (b) => b.pmFormatId === d.id
              )?.id ?? 0,
            pmFormatId: d.id,
            value: 0,
            type: ATTACHMENT_TYPE,
            attachments: uploadedAtt.map((att) => ({
              url: att?.url ?? "",
              gsPath: att?.gsPath ?? "",
              name: att?.name ?? "",
              fileSizeMb: Number(att?.fileSizeMb ?? 0),
              underProject: { id: activeProj?.id ?? 0 },
            })),
          });
          break;
        case SINGLE_OPTION:
          uploadValues.push({
            valueId:
              pmChecklist.singleOptionValues.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: (d.value as {id: number, label: string})?.id.toString() ?? "",
            type: "SINGLE_OPTION",
            attachments: [],
          });
          break;
        case MULTI_OPTION:
          uploadValues.push({
            valueId:
              pmChecklist.multiOptionValues.find((b) => b.pmFormatId === d.id)
                ?.id ?? 0,
            pmFormatId: d.id,
            value: Array.isArray(d.value)
              ? (d.value as Array<{id: number, label: string}>).map((v) => v.id.toString())
              : [],
            type: MULTI_OPTION,
            attachments: [],
          });
          break;
      }
    }

    await updatePmChecklist({
      pmChecklistId: pmChecklist.id.toString(),
      pmTemplateId: pmChecklist.pmTemplateId,
      projectId: activeProj.id ?? 0,
      pmStatusId: pmStatusList?.find((s) => s.name === "Close")?.id ?? 0,
      updatedById: user.id,
      values: uploadValues,
    });
  };

  useSuccess({ dependencies: [updatePmChecklistIsPending] });

  useEffect(() => {
    if (pmChecklist) {
      const orderedList = [...pmChecklist?.pmTemplate.pmFormat].sort(
        (a, b) => a.order - b.order
      );

      // Remove all existing fields
      remove();

      // Append fields with their corresponding values
      orderedList.forEach((ft) => {
        let value;
        let uploadedAttachments;
        switch (ft.pmFormatType.name) {
          case BOOL_TYPE:
            value = pmChecklist.pmChecklistBool.find(
              (b) => b.pmFormatId === ft.id
            )?.value;
            break;
          case VARCHAR_TYPE:
            value = pmChecklist.pmChecklistText.find(
              (t) => t.pmFormatId === ft.id
            )?.value;
            break;
          case NUMBER_TYPE:
            value = pmChecklist.pmChecklistNumber.find(
              (n) => n.pmFormatId === ft.id
            )?.value;
            break;
          case ATTACHMENT_TYPE:
            value = [];
            uploadedAttachments = pmChecklist.pmChecklistAttachment.find(
              (a) => a.pmFormatId === ft.id
            )?.attachments;
            break;
          case "SINGLE_OPTION":
            value = pmChecklist.singleOptionValues.find(
              (b) => b.pmFormatId === ft.id
            )?.selectedOption ? {
              id: pmChecklist.singleOptionValues.find(
                (b) => b.pmFormatId === ft.id
              )?.selectedOption?.id ?? 0,
              label: pmChecklist.singleOptionValues.find(
                (b) => b.pmFormatId === ft.id
              )?.selectedOption?.value ?? ""
            } : undefined;
            break;
          case MULTI_OPTION:
            value = pmChecklist.multiOptionValues
              .find((b) => b.pmFormatId === ft.id)
              ?.selectedOptions?.map((opt) => ({
                id: opt.id,
                label: opt.value
              })) ?? [];
            break;
        }

        append({
          ...ft,
          valueId:
            pmChecklist.pmChecklistAttachment.find(
              (a) => a.pmFormatId === ft.id
            )?.id ?? 0,
          value: (value as any) ?? undefined,
          uploadedAttachments:
            uploadedAttachments?.map((att) => ({
              id: att.id ?? 0,
              url: att.url,
            })) ?? [],
        });
      });
    }

    // Clean up all the fields.
    return () => {
      remove();
    };
  }, [pmChecklist, remove, append]);

  if (pmChecklistIsLoading) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-8">
      <p className="font-sans text-2xl font-bold">PM Checklist</p>
      <FormProvider {...methods}>
        {fields.map((cl, idx) => {
          switch (cl.pmFormatType.name) {
            case BOOL_TYPE:
              return (
                <div key={cl.id} className="flex item-center justify-between gap-2">
                  <div className="flex gap-2">
                    <ToggleLeft className="text-primary-900" />
                    <p>{cl.name}</p>
                  </div>
                  <Controller
                    control={methods.control}
                    name={`pmFormat.${idx}.value`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <Checkbox
                          checked={Boolean(value)}
                          onCheckedChange={(v) => {
                            onChange(!value);
                          }}
                          className="text-primary-900"
                        />
                      );
                    }}
                  />
                </div>
              );
            case VARCHAR_TYPE:
              return (
                <div key={cl.id} className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <TextCursorInput className="text-primary-900" />
                    <p className="font-sans">{cl.name}</p>
                  </div>
                  <Input
                    className="focus-visible:ring-primary-900 bg-slate-50"
                    {...methods.register(`pmFormat.${idx}.value`)}
                  />
                </div>
              );
            case NUMBER_TYPE:
              return (
                <div key={cl.id} className="flex flex-col gap-2">
                  <div className="flex gap-2">
                    <Hash className="text-primary-900" />
                    <p className="font-sans">{cl.name}</p>
                  </div>
                  <Input
                    className="focus-visible:ring-primary-900 bg-slate-50"
                    {...methods.register(`pmFormat.${idx}.value`)}
                    type="number"
                  />
                </div>
              );
            case ATTACHMENT_TYPE:
              return (
                <div key={cl.id}>
                  <Controller
                    control={methods.control}
                    name={`pmFormat.${idx}.value`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <AppCameraFieldFile
                          uploadedPhotos={cl.uploadedAttachments ?? []}
                          onDeleteUploadedPhoto={async (attachment) => {
                            await deletePmAttachment({
                              pmChecklistAttachmentId: cl.valueId ?? 0,
                              attachmentId: attachment.id ?? 0,
                            });

                            // Update only the specific field
                            const updatedAttachments =
                              cl.uploadedAttachments?.filter(
                                (att) => att.id !== attachment.id
                              ) ?? [];

                            update(idx, {
                              ...cl,
                              id: cl.valueId ?? 0,
                              uploadedAttachments: updatedAttachments,
                            });
                          }}
                          label="Documents"
                          onChange={onChange}
                          onDelete={(url) => {
                            if (!value) return;
                            const newFiles = (value as File[]).filter(
                              (v) => v !== url
                            );
                            onChange(newFiles);
                          }}
                          photos={(value as File[]) ?? []}
                          error={!!error}
                        />
                      );
                    }}
                  />
                </div>
              );
            case "SINGLE_OPTION":
              return (
                <div key={cl.id}>
                  <Controller
                    control={methods.control}
                    name={`pmFormat.${idx}.value`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <AppSelectWithDialog
                          control={methods.control}
                          name={`pmFormat.${idx}.value`}
                          defaultValue={undefined}
                          columns={[
                            {
                              accessorKey: "id",
                            },
                            {
                              header: "",
                              id: "select",
                              cell: ({ row, table }) => (
                                <Checkbox
                                  checked={row.getIsSelected()}
                                  onCheckedChange={(value) => {
                                    row.toggleSelected(!!value);
                                  }}
                                  aria-label="Select row"
                                />
                              ),
                              enableSorting: false,
                              enableHiding: false,
                            },
                            {
                              header: "Name",
                              accessorFn: (row) => row.label,
                            },
                          ]}
                          items={
                            cl.formatOptions?.map((opt) => ({
                              id: opt.id,
                              label: opt.value,
                            })) || []
                          }
                          label={cl.name}
                          placeholder={`Select ${cl.name}`}
                          dialogTitle={`Select ${cl.name}`}
                          error={!!error}
                          helperText={error?.message}
                          multiple={false}
                          onResultRender={(item) => (
                            <div className="flex flex-col">
                              <div className="font-medium">{item?.label}</div>
                            </div>
                          )}
                          onOptionsRender={(item) => (
                            <div className="flex flex-col">
                              <div className="font-medium">{item?.label}</div>
                            </div>
                          )}
                        />
                      );
                    }}
                  />
                </div>
              );
            case "MULTI_OPTION":
              return (
                <div key={cl.id}>
                  <Controller
                    control={methods.control}
                    name={`pmFormat.${idx}.value`}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <AppSelectWithDialog
                          control={methods.control}
                          name={`pmFormat.${idx}.value`}
                          defaultValue={[]}
                          columns={[
                            {
                              accessorKey: "id",
                            },
                            {
                              header: "",
                              id: "select",
                              cell: ({ row, table }) => (
                                <Checkbox
                                  checked={row.getIsSelected()}
                                  onCheckedChange={(value) => {
                                    row.toggleSelected(!!value);
                                  }}
                                  aria-label="Select row"
                                />
                              ),
                              enableSorting: false,
                              enableHiding: false,
                            },
                            {
                              header: "Name",
                              accessorFn: (row) => row.label,
                            },
                          ]}
                          items={
                            cl.formatOptions?.map((opt) => ({
                              id: opt.id,
                              label: opt.value,
                            })) || []
                          }
                          label={cl.name}
                          placeholder={`Select ${cl.name}`}
                          dialogTitle={`Select ${cl.name}`}
                          error={!!error}
                          helperText={error?.message}
                          multiple={true}
                          onResultRender={(item) => (
                            <div className="flex flex-col">
                              <div className="font-medium">{item?.label}</div>
                            </div>
                          )}
                          onOptionsRender={(item) => (
                            <div className="flex flex-col">
                              <div className="font-medium">{item?.label}</div>
                            </div>
                          )}
                        />
                      );
                    }}
                  />
                </div>
              );
            default:
              return <div>error</div>;
          }
        })}
      </FormProvider>
      <div className="flex">
        <AppButton
          isLoading={attIsLoading || updatePmChecklistIsPending}
          label="Submit"
          onClick={methods.handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
}
