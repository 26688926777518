import { z } from "zod";

export const getAnalyticsInput = z.object({
  projectId: z.number(),
});

export type GetAnalyticsInput = z.infer<typeof getAnalyticsInput>;

export const getPMApportionmentInput = z.object({
  projectId: z.number(),
});

export type GetPMApportionmentInput = z.infer<typeof getPMApportionmentInput>;

// Add the new type for getJkrKpiReport
export const getJkrKpiReportInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetJkrKpiReportInput = z.infer<typeof getJkrKpiReportInput>;

export const getWorkRequestSubmissionTrendInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetWorkRequestSubmissionTrendInput = z.infer<typeof getWorkRequestSubmissionTrendInput>;

export const getWorkRequestStatusBreakdownInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkRequestStatusBreakdownInput = z.infer<typeof getWorkRequestStatusBreakdownInput>;

export const getWorkRequestAvgTurnaroundTimeInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkRequestAvgTurnaroundTimeInput = z.infer<typeof getWorkRequestAvgTurnaroundTimeInput>;

// Add the new type for getAvgWorkRequestRating
export const getAvgWorkRequestRatingInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetAvgWorkRequestRatingInput = z.infer<typeof getAvgWorkRequestRatingInput>;

export const getWorkOrderTrendInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetWorkOrderTrendInput = z.infer<typeof getWorkOrderTrendInput>;

export const getWorkOrderTypeApportionmentInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkOrderTypeApportionmentInput = z.infer<typeof getWorkOrderTypeApportionmentInput>;

export const getWorkOrderClosureRateInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkOrderClosureRateInput = z.infer<typeof getWorkOrderClosureRateInput>;

export const getPMTrendWithStatusInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetPMTrendWithStatusInput = z.infer<typeof getPMTrendWithStatusInput>;

export const getPMApportionmentByStatusInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetPMApportionmentByStatusInput = z.infer<typeof getPMApportionmentByStatusInput>;

export const getAssetPPMCompletionRateSchema = z.object({
  projectId: z.number(),
  limit: z.number().optional(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetAssetPPMCompletionRateInput = z.infer<typeof getAssetPPMCompletionRateSchema>;

export const getWorkRequestKpiAvgTurnaroundTimeInput = z.object({
  month: z.number().optional(),
  year: z.number().optional(),
  projectId: z.number(),
});

export type GetWorkRequestKpiAvgTurnaroundTimeInput = z.infer<typeof getWorkRequestKpiAvgTurnaroundTimeInput>;

export const getWorkRequestDayTrendTurnaroundTimeInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetWorkRequestDayTrendTurnaroundTimeInput = z.infer<typeof getWorkRequestDayTrendTurnaroundTimeInput>;

export const getExpiredContractPieChartInput = z.object({
  projectId: z.number(),
});

export type GetExpiredContractPieChartInput = z.infer<typeof getExpiredContractPieChartInput>;

export const getPMUpdatedTrendChartInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetPMUpdatedTrendChartInput = z.infer<typeof getPMUpdatedTrendChartInput>;

export const getFMContractInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetFMContractInput = z.infer<typeof getFMContractInput>;

export const getRemedialExpensesCategoryPieChartInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetRemedialExpensesCategoryPieChartInput = z.infer<typeof getRemedialExpensesCategoryPieChartInput>;

export const getRemedialExpensesTrendInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetRemedialExpensesTrendInput = z.infer<typeof getRemedialExpensesTrendInput>;

export const getMultipleProjectWRSubmissionTrendInput = z.object({
  projectIds: z.array(z.number()),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetMultipleProjectWRSubmissionTrendInput = z.infer<typeof getMultipleProjectWRSubmissionTrendInput>;

export const getMultipleAssetsUnderRCIInput = z.object({
  projectIds: z.array(z.number()),
});

export type GetMultipleAssetsUnderRCIInput = z.infer<typeof getMultipleAssetsUnderRCIInput>;

export const getMultipleProjectWOTrendInput = z.object({
  projectIds: z.array(z.number()),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetMultipleProjectWOTrendInput = z.infer<typeof getMultipleProjectWOTrendInput>;

export const getStaffBreakdownUnderRCIInput = z.object({
  projectIds: z.array(z.number()),
});

export type GetStaffBreakdownUnderRCIInput = z.infer<typeof getStaffBreakdownUnderRCIInput>;

export const getStaffWithWorkOrderCountInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetStaffWithWorkOrderCountInput = z.infer<typeof getStaffWithWorkOrderCountInput>;

export const getWorkOrderProfitBarTrendInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkOrderProfitBarTrendInput = z.infer<typeof getWorkOrderProfitBarTrendInput>;

export const getWorkOrderProfitBarTrendByUserInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkOrderProfitBarTrendByUserInput = z.infer<typeof getWorkOrderProfitBarTrendByUserInput>;

export const getCustomerProfitApportionmentInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetCustomerProfitApportionmentInput = z.infer<typeof getCustomerProfitApportionmentInput>;

export const getWorkOrderRevenueBarTrendInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkOrderRevenueBarTrendInput = z.infer<typeof getWorkOrderRevenueBarTrendInput>;

export const getWorkOrderRevenueBarTrendByUserInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkOrderRevenueBarTrendByUserInput = z.infer<typeof getWorkOrderRevenueBarTrendByUserInput>;

export const getCustomerRevenueApportionmentInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetCustomerRevenueApportionmentInput = z.infer<typeof getCustomerRevenueApportionmentInput>;

export const getFmStatutoryContractsInput = z.object({
  projectId: z.number(),
});

export type GetFmStatutoryContractsInput = z.infer<typeof getFmStatutoryContractsInput>;

export const getPlannedPmDoneOnScheduleInput = z.object({
  projectId: z.number(),
  year: z.number().optional(),
  month: z.number().optional(),
});

export type GetPlannedPmDoneOnScheduleInput = z.infer<typeof getPlannedPmDoneOnScheduleInput>;

export const getSystemUptimePercentageInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetSystemUptimePercentageInput = z.infer<typeof getSystemUptimePercentageInput>;

// Input schema – you can extend this if you want to add month/year filtering.
export const getRepairTimePerformanceInput = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});
export type GetRepairTimePerformanceInput = z.infer<
  typeof getRepairTimePerformanceInput
>;

// Define the expected input (optionally you can pass in a year and month)
const getAdmcWrResponseTimeSchema = z.object({
  year: z.number().optional(),
  month: z.number().optional(),
  projectId: z.number(),
});

export type GetAdmcWrResponseTimeInput = z.infer<typeof getAdmcWrResponseTimeSchema>;
