import { Check, Hash, Image, List, ListChecks, Text, ToggleLeft } from "lucide-react";

interface FormatTypeProps {
  formatType: "VARCHAR" | "NUMBER" | "BOOL" | "ATTACHMENT" | "SINGLE_OPTION" | "MULTI_OPTION" | string;
}

export default function FormatType({ formatType }: FormatTypeProps) {
  switch (formatType) {
    case "VARCHAR":
      return (
        <div className="flex gap-4 items-center">
          <div className="p-2 bg-primary-400 flex items-center justify-center rounded-lg">
            <Text className="h-4 w-4 text-primary-900" />
          </div>
          <p className="font-medium">Text</p>
        </div>
      );
    case "NUMBER":
      return (
        <div className="flex gap-4 items-center">
          <div className="p-2 bg-primary-400 flex items-center justify-center rounded-lg">
            <Hash className="h-4 w-4 text-primary-900" />
          </div>
          <p className="font-medium">Number</p>
        </div>
      );
    case "BOOL":
      return (
        <div className="flex gap-4 items-center">
          <div className="p-2 bg-primary-400 flex items-center justify-center rounded-lg">
            <ToggleLeft className="h-4 w-4 text-primary-900" />
          </div>
          <p className="font-medium">Yes / No</p>
        </div>
      );
    case "ATTACHMENT":
      return (
        <div className="flex gap-4 items-center">
          <div className="p-2 bg-primary-400 flex items-center justify-center rounded-lg">
            <Image className="h-4 w-4 text-primary-900" />
          </div>
          <p className="font-medium">Image</p>
        </div>
      );
    case "SINGLE_OPTION":
      return (
        <div className="flex gap-4 items-center">
          <div className="p-2 bg-primary-400 flex items-center justify-center rounded-lg">
            <List className="h-4 w-4 text-primary-900" />
          </div>
          <p className="font-medium">Single Choice</p>
        </div>
      );
    case "MULTI_OPTION":
      return (
        <div className="flex gap-4 items-center">
          <div className="p-2 bg-primary-400 flex items-center justify-center rounded-lg">
            <ListChecks className="h-4 w-4 text-primary-900" />
          </div>
          <p className="font-medium">Multiple Choice</p>
        </div>
      );
    default:
      return <></>;
  }
}
