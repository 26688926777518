import { FileImage, Hash, TextCursorInput, ToggleLeft, Tag, Tags } from "lucide-react";
import AppItemInfo from "../../../AppItemInfo";
import AppMultiImage from "../../../AppMultiImage";

export type PmFormatType = 
  | "VARCHAR"
  | "NUMBER"
  | "BOOL"
  | "ATTACHMENT"
  | "SINGLE_OPTION"
  | "MULTI_OPTION";

interface PmFormatFieldProps {
  type: PmFormatType;
  name: string;
  value: any;
}

export default function PmFormatField({ type, name, value }: PmFormatFieldProps) {
  switch (type) {
    case "VARCHAR":
      return (
        <AppItemInfo
          label={name}
          icon={<TextCursorInput className="text-primary-900" />}
          content={<p className="font-sans">{value ?? "-"}</p>}
        />
      );

    case "NUMBER":
      return (
        <AppItemInfo
          label={name}
          icon={<Hash className="text-primary-900" />}
          content={<p className="font-sans">{Number(value) ?? "-"}</p>}
        />
      );

    case "BOOL":
      return (
        <AppItemInfo
          label={name}
          icon={<ToggleLeft className="text-primary-900" />}
          content={<p className="font-sans">{value ? "YES" : "NO"}</p>}
        />
      );

    case "ATTACHMENT":
      return (
        <AppItemInfo
          label={name}
          icon={<FileImage className="text-primary-900" />}
          content={<AppMultiImage attachments={value} />}
        />
      );

    case "SINGLE_OPTION":
      return (
        <AppItemInfo
          label={name}
          icon={<Tag className="text-primary-900" />}
          content={<p className="font-sans">{value?.value ?? "-"}</p>}
        />
      );

    case "MULTI_OPTION":
      return (
        <AppItemInfo
          label={name}
          icon={<Tags className="text-primary-900" />}
          content={
            <div className="flex flex-wrap gap-2">
              {value?.length > 0 ? (
                value.map((option: any) => (
                  <span key={option.id} className="bg-gray-100 px-2 py-1 rounded-md">
                    {option.value}
                  </span>
                ))
              ) : (
                "-"
              )}
            </div>
          }
        />
      );

    default:
      return null;
  }
} 