import { AssetPPMCompletionRate } from "../PMAnalytics/AssetPPMCompletionRate";
import { AssetPPMCompletionRateTable } from "../AssetPPMCompletionRateTable/AssetPPMCompletionRateTable";
import { PMApportionmentStatusPieChart } from "../PMAnalytics/PMApportionmentStatusPieChart";
import { PmStatusCountSection } from "../PMAnalytics/PmStatusCountSection";
import { PMTrendAreaChart } from "../PMAnalytics/PMTrendAreaChart";
import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import { PMUpdatedTrendChart } from "../PMAnalytics/PMUpdatedTrendChart";
import SelectableChart from "../../../../components/SelectableChart";

export function AdmcPmAnalytic() {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between mt-2 col-span-2">
        <DateFilter />
      </div>
      <div className="col-span-2">
        <SelectableChart chartId="admc-pm-apportionment-status-pie-chart">
          <PMApportionmentStatusPieChart />
        </SelectableChart>
      </div>
      <PmStatusCountSection />
      <div className="col-span-2">
        <SelectableChart chartId="admc-pm-trend-area-chart">
          <PMTrendAreaChart />
        </SelectableChart>
      </div>
      <div className="col-span-2">
        <SelectableChart chartId="admc-pm-updated-trend-chart">
          <PMUpdatedTrendChart />
        </SelectableChart>
      </div>
      <div className="col-span-2">
        <AssetPPMCompletionRateTable />
      </div>
    </div>
  );
} 