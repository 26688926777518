import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { RouterInputs } from "@cerev-cmms/trpc";
import { Check, Plus, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Card, CardContent } from "../../../../../components/ui/card";
import AppButton from "../../../../../components/AppButton";
import AppTextField from "../../../../../components/AppTextField";
import AppSelect from "../../../../../components/AppSelect";
import { Switch } from "../../../../../components/ui/switch";
import { useFieldArray } from "react-hook-form";
import { trpc } from "../../../../../lib/trpc";
import { useAppSelector } from "../../../../../redux/store";
import AppSelectWithDialog from "../../../../../components/dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { Checkbox } from "../../../../../components/ui/checkbox";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../../../../../components/ui/accordion";

export type DynamicField = NonNullable<
  NonNullable<
    RouterInputs["workRequest"]["updateWorkRequestSetting"]["dynamicFields"]
  >["fields"]
>[number];

const fieldTypes = [
  { value: "text", label: "Text" },
  { value: "select", label: "Single Select" },
  { value: "multiselect", label: "Multi Select" },
] as const;

const formSchema = z
  .object({
    id: z.string(),
    label: z.string().min(1, "Label is required"),
    type: z.enum([
      "text",
      "number",
      "date",
      "select",
      "multiselect",
      "checkbox",
    ]),
    required: z.boolean(),
    order: z.number().min(0),
    options: z
      .array(
        z.object({
          label: z.string().min(1, "Option label is required"),
          notifications: z.array(
            z.object({ id: z.number(), name: z.string() })
          ),
        })
      )
      .optional(),
    visibility: z.enum(["VISIBLE", "HIDDEN"]),
  })
  .refine(
    (data) => {
      if (data.type === "select" || data.type === "multiselect") {
        return data.options && data.options?.length > 0;
      }
      return true;
    },
    {
      message: "Options are required for select and multiselect fields",
      path: ["options"],
    }
  );

type FormValues = z.infer<typeof formSchema>;

interface DynamicFieldDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  editingField: DynamicField | null;
  onSubmit: (values: DynamicField) => void;
  isPending: boolean;
}

export default function DynamicFieldDialog({
  open,
  onOpenChange,
  editingField,
  onSubmit,
  isPending,
}: DynamicFieldDialogProps) {
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: "",
      label: "",
      type: "text",
      required: false,
      order: 0,
      options: [],
      visibility: "VISIBLE",
    },
  });

  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "options",
  });

  const selectedType = methods.watch("type");
  const showOptions =
    selectedType === "select" || selectedType === "multiselect";

  const { data: users } = trpc.user.getUsers.useQuery(
    {
      projectId: activeProj?.id ?? 0,
    },
    {
      enabled: !!activeProj?.id,
    }
  );

  const userOptions =
    users?.map((user) => ({
      id: user.id,
      name: user.name ?? "",
      position: user.position,
    })) || [];

  const handleSubmit: SubmitHandler<FormValues> = (values) => {
    onSubmit(values);
  };

  // Reset form when editing field changes
  useEffect(() => {
    if (editingField) {
      methods.reset({
        ...editingField,
        options: editingField.options || [],
      });
    } else {
      methods.reset({
        id: "",
        label: "",
        type: "text",
        required: false,
        order: 0,
        options: [],
        visibility: "VISIBLE",
      });
    }
  }, [editingField, methods]);

  useEffect(() => {
    if (!open) methods.reset();
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-full max-w-2xl overflow-y-scroll max-h-screen flex flex-col justify-start">
        <DialogHeader>
          <DialogTitle>
            {editingField ? "Edit Field" : "Add New Field"}
          </DialogTitle>
          <DialogDescription>
            Adjust here for additional fields that you wish for your users to
            fill in as part of the work request
          </DialogDescription>
        </DialogHeader>

        <FormProvider {...methods}>
          <div className="space-y-6">
            <AppTextField name="label" label="Label" />

            <AppSelect
              name="type"
              label="Field Type"
              selections={fieldTypes.map((type) => ({
                label: type.label,
                value: type.value,
              }))}
              defaultValue={fieldTypes[0].value}
            />

            {showOptions && (
              <div className="space-y-8">
                <div className="flex flex-col gap-2">
                  <h3 className="text-lg font-semibold">Field Options</h3>
                  <p className="text-sm text-muted-foreground">
                    Define the options that will be available for users to
                    select from.
                  </p>
                </div>

                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium">Option List</p>
                  <AppButton
                    variant="outline"
                    size="sm"
                    onClick={() => append({ label: "", notifications: [] })}
                    icon={<Plus className="h-4 w-4" />}
                  >
                    Add Option
                  </AppButton>
                </div>

                <div className="space-y-6">
                  {fields.map((field, index) => (
                    <Card key={field.id} className="p-4">
                      <div className="space-y-4">
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <span className="flex items-center justify-center w-6 h-6 rounded-full bg-primary-100 text-primary-900 text-sm font-medium">
                                {index + 1}
                              </span>
                              <h4 className="text-sm font-medium">
                                Option Details
                              </h4>
                            </div>
                            <AppButton
                              variant="ghost"
                              size="icon"
                              onClick={() => remove(index)}
                              icon={<X className="h-4 w-4" />}
                            />
                          </div>

                          <div className="space-y-4">
                            <div className="flex-1">
                              <AppTextField
                                name={`options.${index}.label`}
                                placeholder="Enter option label"
                                className="w-full"
                              />
                            </div>

                            <div className="h-px bg-slate-200" />

                            <Accordion
                              type="single"
                              collapsible
                              className="w-full"
                            >
                              <AccordionItem
                                value="notifications"
                                className="border-none"
                              >
                                <AccordionTrigger className="py-2 hover:no-underline">
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium">
                                      Advanced Settings
                                    </span>
                                    <span className="text-xs px-2 py-0.5 rounded-full bg-slate-100 text-slate-600">
                                      Notifications
                                    </span>
                                  </div>
                                </AccordionTrigger>
                                <AccordionContent>
                                  <div className="pt-2">
                                    <AppSelectWithDialog
                                      multiple
                                      label="Users to be notified when this option is chosen"
                                      placeholder="Select users to notify"
                                      defaultValue={[]}
                                      control={methods.control}
                                      name={`options.${index}.notifications`}
                                      items={userOptions}
                                      columns={[
                                        {
                                          header: "",
                                          id: "select",
                                          cell: ({ row, table }) => (
                                            <Checkbox
                                              checked={row.getIsSelected()}
                                              onCheckedChange={(value) => {
                                                row.toggleSelected(!!value);
                                              }}
                                              aria-label="Select row"
                                            />
                                          ),
                                          enableSorting: false,
                                          enableHiding: false,
                                        },
                                        {
                                          id: "name",
                                          header: "Name",
                                          accessorKey: "name",
                                        },
                                        {
                                          id: "position",
                                          header: "Position",
                                          accessorKey: "position",
                                        },
                                      ]}
                                      onResultRender={(item) => (
                                        <div className="flex flex-col">
                                          <div className="font-medium">
                                            {item?.name}
                                          </div>
                                          <div className="font-thin mt-1">
                                            {item?.position ?? "-"}
                                          </div>
                                        </div>
                                      )}
                                      onOptionsRender={(item) => (
                                        <div className="flex flex-col">
                                          <div className="font-medium">
                                            {item?.name}
                                          </div>
                                          <div className="font-thin mt-1">
                                            {item?.position ?? "-"}
                                          </div>
                                        </div>
                                      )}
                                      dialogTitle="Select Users to Notify"
                                    />
                                  </div>
                                </AccordionContent>
                              </AccordionItem>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>

                {methods.formState.errors.options && (
                  <p className="text-sm text-red-500">
                    {methods.formState.errors.options.message}
                  </p>
                )}
              </div>
            )}

            <Card>
              <CardContent className="p-4 flex gap-4 items-center justify-between">
                <div>
                  <p className="text-lg font-medium">Required Field</p>
                  <p className="text-sm leading-relaxed text-slate-500">
                    Make this field mandatory for users to fill in
                  </p>
                </div>
                <Switch
                  className="data-[state=checked]:bg-primary-900"
                  checked={methods.watch("required")}
                  onCheckedChange={(checked) =>
                    methods.setValue("required", checked)
                  }
                />
              </CardContent>
            </Card>

            <DialogFooter className="mt-6">
              <AppButton variant="ghost" onClick={() => onOpenChange(false)}>
                Cancel
              </AppButton>
              <AppButton
                onClick={methods.handleSubmit(handleSubmit)}
                isLoading={isPending}
                icon={<Check className="w-4 w-4" />}
              >
                {editingField ? "Update" : "Add"}
              </AppButton>
            </DialogFooter>
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
