import { useFormContext } from "react-hook-form";
import { Button } from "../../../ui/button";
import { Plus, Trash2 } from "lucide-react";
import AppTextField from "../../../AppTextField";
import { PmTemplateFormType } from "../PmTemplateFormSchema";

interface FormatOptionsProps {
  formatIndex: number;
}

export default function FormatOptions({ formatIndex }: FormatOptionsProps) {
  const { watch, setValue } = useFormContext<PmTemplateFormType>();
  const currentFormat = watch(`pmFormat.${formatIndex}`);
  const options = currentFormat?.options || [];

  const addOption = () => {
    const updatedFormat = {
      ...currentFormat,
      options: [
        ...options,
        {
          value: "",
          order: options.length + 1,
        }
      ],
    };
    setValue(`pmFormat.${formatIndex}`, updatedFormat);
  };

  const removeOption = (optionIndex: number) => {
    const updatedFormat = {
      ...currentFormat,
      options: options.filter((_, idx) => idx !== optionIndex).map((opt, idx) => ({
        ...opt,
        order: idx + 1
      })),
    };
    setValue(`pmFormat.${formatIndex}`, updatedFormat);
  };

  return (
    <div className="flex flex-col gap-2 mt-2">
      <div className="flex justify-between items-center">
        <label className="text-sm font-medium">Options</label>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={addOption}
          className="flex items-center gap-1"
        >
          <Plus className="w-4 h-4" />
          Add Option
        </Button>
      </div>
      {options.map((_, optionIndex) => (
        <div key={optionIndex} className="flex items-center gap-2">
          <AppTextField
            name={`pmFormat.${formatIndex}.options.${optionIndex}.value`}
            placeholder="Enter option"
            className="flex-1"
            data-testid={`${currentFormat?.name}-option-${optionIndex}`}
          />
          <Button
            type="button"
            variant="ghost"
            size="sm"
            onClick={() => removeOption(optionIndex)}
            data-testid={`${currentFormat?.name}-remove-option-${optionIndex}`}
          >
            <Trash2 className="w-4 h-4 text-red-500" />
          </Button>
        </div>
      ))}
      {options.length === 0 && (
        <p className="text-sm text-gray-500">No options added yet</p>
      )}
    </div>
  );
} 