import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useSelectedSettingProjectId } from "../../../../stores/settingStore/settingStore";
import { useUserControllerGetOneUser } from "../../../../api/users/users";
import { Card, CardDescription, CardHeader, CardTitle } from "../../../ui/card";
import { Loader2 } from "lucide-react";
import { Switch } from "../../../ui/switch";
import { trpc } from "../../../../lib/trpc";

export default function AmendAdvanceFieldsWorkOrder() {
  const qc = useQueryClient();
  const [searchParams] = useSearchParams();
  const selectedProjId = useSelectedSettingProjectId();
  const firebaseId = searchParams.get("firebaseId");
  const { data: user } = useUserControllerGetOneUser(firebaseId ?? "", {
    query: {
      enabled: !!firebaseId,
      select: (res) => res.data,
    },
  });

  const { data: policy } = trpc.policies.checkOneUserPolicy.useQuery(
    {
      policyName: "AMEND_ADVANCE_FIELDS_WORK_ORDER",
      projectId: selectedProjId ?? "",
      userId: user?.id ?? 0
    },
    {
      enabled: !!selectedProjId && !!user?.id,
    }
  );

  const { mutate, isPending } = trpc.policies.updateUserPolicy.useMutation({
    onSuccess: () => {
      qc.invalidateQueries({
        predicate: (q) => (q.queryKey[0] as string).includes("policies"),
      });
    },
  });

  const onCheckChange = (checked: boolean) => {
    if (user?.id && selectedProjId) {
      mutate({
        projectId: selectedProjId,
        policyName: "AMEND_ADVANCE_FIELDS_WORK_ORDER",
        policyStatus: checked ? "ALLOWED" : "NOT_ALLOWED",
        userId: user.id,
      });
    }
  };

  return (
    <Card>
      <div className="flex items-center mr-6">
        <CardHeader className="flex-1 mr-4">
          <CardTitle className="text-lg">Modify Advanced Work Order Fields</CardTitle>
          <CardDescription>
            Allow user to modify reference number and creation date in work orders
          </CardDescription>
        </CardHeader>
        {isPending && (
          <Loader2 className="w-6 h-6 animate-spin text-primary-900 mr-2" />
        )}
        <Switch
          className="data-[state=checked]:bg-primary-900"
          checked={policy?.data === "ALLOWED"}
          onCheckedChange={onCheckChange}
        />
      </div>
    </Card>
  );
}
