import { useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "../../../../../redux/store";
import { trpc } from "../../../../../lib/trpc";
import { Button } from "../../../../../components/ui/button";
import { Plus, Trash2, Edit2 } from "lucide-react";
import { useState } from "react";
import { RouterInputs } from "@cerev-cmms/trpc/src/lib/trpc/root";
import DynamicFieldDialog from "./DynamicFieldDialog";
import AppButton from "../../../../../components/AppButton";

const fieldTypes = [
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "date", label: "Date" },
  { value: "select", label: "Single Select" },
  { value: "multiselect", label: "Multi Select" },
  { value: "checkbox", label: "Checkbox" },
] as const;

export type DynamicField = NonNullable<
  NonNullable<
    RouterInputs["workRequest"]["updateWorkRequestSetting"]["dynamicFields"]
  >["fields"]
>[number];

type WorkRequestDynamicFields =
  RouterInputs["workRequest"]["updateWorkRequestSetting"]["dynamicFields"];

export default function DynamicSetting() {
  const [open, setOpen] = useState(false);
  const [editingField, setEditingField] = useState<DynamicField | null>(null);
  const qc = useQueryClient();
  const utils = trpc.useUtils();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const { data: workReqSettings } =
    trpc.workRequest.getOneWorkRequestSetting.useQuery(
      {
        projectId: activeProj?.id ?? 0,
      },
      {
        enabled: !!activeProj,
        select: (res) => res.data,
      }
    );

  const { mutate, isPending } =
    trpc.workRequest.updateWorkRequestSetting.useMutation({
      onSuccess: () => {
        qc.invalidateQueries({
          predicate: (q) => {
            utils.workRequest.getOneWorkRequestSetting.invalidate();
            return (q.queryKey[0] as string).includes("work-request-setting");
          },
        });
        setOpen(false);
        setEditingField(null);
      },
    });

  function onSubmit(values: DynamicField) {
    if (!workReqSettings || !activeProj?.id) return;

    const currentFields =
      (workReqSettings.dynamicFields as WorkRequestDynamicFields)?.fields || [];
    let updatedFields: DynamicField[];

    if (editingField) {
      updatedFields = currentFields.map((field: DynamicField) =>
        field.id === editingField.id ? values : field
      );
    } else {
      updatedFields = [
        ...currentFields,
        { ...values, id: crypto.randomUUID() },
      ];
    }

    mutate({
      ...workReqSettings,
      projectId: activeProj.id,
      dynamicFields: {
        fields: updatedFields,
      },
    });
  }

  function handleDelete(fieldId: string) {
    if (!workReqSettings || !activeProj?.id) return;

    const currentFields =
      (workReqSettings.dynamicFields as WorkRequestDynamicFields)?.fields || [];
    const updatedFields = currentFields.filter(
      (field: DynamicField) => field.id !== fieldId
    );

    mutate({
      ...workReqSettings,
      projectId: activeProj.id,
      dynamicFields: {
        fields: updatedFields,
      },
    });
  }

  const dynamicFields =
    (workReqSettings?.dynamicFields as WorkRequestDynamicFields)?.fields || [];

  return (
    <div className="mt-16">
      <div className="flex items-center justify-between mb-4">
        <div className="space-y-2">
          <h3 className="text-2xl font-bold">Dynamic Fields</h3>
          <p className="text-sm text-slate-600">
            Adjust here for additional fields that you wish for your users to
            fill in as part of the work request
          </p>
        </div>
        <AppButton
          onClick={() => {
            setEditingField(null);
            setOpen(true);
          }}
          icon={<Plus className="w-4 h-4" />}
        >
          Add Field
        </AppButton>
      </div>

      <DynamicFieldDialog
        open={open}
        onOpenChange={setOpen}
        editingField={editingField}
        onSubmit={onSubmit}
        isPending={isPending}
      />

      <div className="space-y-4">
        {dynamicFields.map((field: DynamicField) => (
          <div
            key={field.id}
            className="flex items-center justify-between p-4 border rounded-lg"
          >
            <div>
              <h4 className="font-medium">{field.label}</h4>
              <p className="text-sm text-gray-500">
                Type: {fieldTypes.find((t) => t.value === field.type)?.label}
              </p>
              {field.options && field.options.length > 0 && (
                <div className="mt-2 space-y-2">
                  <p className="text-sm font-medium text-gray-600">Options:</p>
                  <div className="grid gap-2">
                    {field.options.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-start gap-2 p-2 bg-slate-50 rounded-md"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center w-6 h-6 rounded-full bg-primary-100 text-primary-900 text-xs font-medium">
                          {index + 1}
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {option.label}
                          </p>
                          {option.notifications && option.notifications.length > 0 ? (
                            <div className="mt-1">
                              <div className="flex items-center gap-1.5 text-xs text-gray-500">
                                <svg
                                  className="w-3.5 h-3.5"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                  />
                                </svg>
                                <span className="font-medium">Notifies:</span>
                                <div className="flex items-center gap-1">
                                  {option.notifications.length > 3 ? (
                                    <>
                                      {option.notifications.slice(0, 2).map((user, idx) => (
                                        <span
                                          key={idx}
                                          className="inline-flex items-center px-1.5 py-0.5 rounded bg-primary-50 text-primary-700"
                                        >
                                          {user.name}
                                        </span>
                                      ))}
                                      <span className="inline-flex items-center px-1.5 py-0.5 rounded bg-primary-50 text-primary-700">
                                        +{option.notifications.length - 2} more
                                      </span>
                                    </>
                                  ) : (
                                    option.notifications.map((user, idx) => (
                                      <span
                                        key={idx}
                                        className="inline-flex items-center px-1.5 py-0.5 rounded bg-primary-50 text-primary-700"
                                      >
                                        {user.name}
                                      </span>
                                    ))
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p className="mt-0.5 text-xs text-gray-500">No notifications set</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex gap-2 mt-1">
                {field.required && (
                  <span className="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded">
                    Required
                  </span>
                )}
                {field.visibility === "HIDDEN" && (
                  <span className="px-2 py-1 text-xs bg-gray-100 text-gray-800 rounded">
                    Hidden
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setEditingField(field);
                  setOpen(true);
                }}
              >
                <Edit2 className="w-4 h-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleDelete(field.id)}
              >
                <Trash2
                  className="w-4 h-4 text-red-500"
                  data-testid="delete-dynamic-field"
                />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
