import { Project } from "@cerev-cmms/prisma";
import { z } from "zod";

const workOrder = z.object({
  refNo: z.string().optional(),
  workRequests: z
    .object({ trackingCode: z.string(), email: z.string().optional() })
    .array()
    .optional(),
  defectType: z.object({
    name: z.string().optional(),
  }),
  createdOn: z.string().optional(),
  createdBy: z.object({
    name: z.string().optional(),
  }),
  photos: z
    .object({
      url: z.string().optional(),
    })
    .array()
    .optional(),
});

export type JKRWorkOrder = z.infer<typeof workOrder>;

export const getWorkOrdersSchema = z.object({
  locationId: z.string().optional(),
  workOrderStatusId: z.string().optional(),
  projectId: z.string().optional(),
  vendorId: z.string().optional(),
  locFilterId: z.string().optional(),
  plannedDateStartISO: z.string().optional(),
  plannedDateEndISO: z.string().optional(),
  filterStartDateISO: z.string().optional(),
  filterEndDateISO: z.string().optional(),
  filterAssetTypeIds: z.array(z.string()).optional(),
  filterRaisedByIds: z.array(z.string()).optional(),
  filterWorkOrderTypeIds: z.array(z.string()).optional(),
  filterWorkTradeIds: z.array(z.string()).optional(),
  filterPriorityIds: z.array(z.string()).optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
  cursor: z.number().nullish(),
  search: z.string().optional(),
});

export type GetWorkOrdersInput = z.infer<typeof getWorkOrdersSchema>;

// Add the DeleteCommentInput type
export const deleteCommentSchema = z.object({
  commentId: z.number(),
});

export type DeleteCommentInput = z.infer<typeof deleteCommentSchema>;

export const createWorkOrderPrioritySchema = z.object({
  name: z.string(),
  order: z.number(),
  projectId: z.number(),
});

export type CreateWorkOrderPriorityInput = z.infer<
  typeof createWorkOrderPrioritySchema
>;

export const getOneWorkOrderPrioritySchema = z.object({
  id: z.number(),
});

export type GetOneWorkOrderPriorityInput = z.infer<
  typeof getOneWorkOrderPrioritySchema
>;

export const deleteWorkOrderPrioritySchema = z.object({
  id: z.number(),
});

export type DeleteWorkOrderPriorityInput = z.infer<
  typeof deleteWorkOrderPrioritySchema
>;

export const updateWorkOrderPrioritySchema = z.object({
  id: z.number(),
  name: z.string().optional(),
  order: z.number().optional(),
});

export type UpdateWorkOrderPriorityInput = z.infer<
  typeof updateWorkOrderPrioritySchema
>;

export const getWorkOrderPrioritiesSchema = z.object({
  projectId: z.number(),
});

export type GetWorkOrderPrioritiesInput = z.infer<
  typeof getWorkOrderPrioritiesSchema
>;

export const createWorkTradeSchema = z.object({
  name: z.string(),
  order: z.number(),
  projectId: z.number(),
});

export type CreateWorkTradeInput = z.infer<typeof createWorkTradeSchema>;

export const getOneWorkTradeSchema = z.object({
  id: z.number(),
});

export type GetOneWorkTradeInput = z.infer<typeof getOneWorkTradeSchema>;

export const deleteWorkTradeSchema = z.object({
  id: z.number(),
});

export type DeleteWorkTradeInput = z.infer<typeof deleteWorkTradeSchema>;

export const updateWorkTradeSchema = z.object({
  id: z.number(),
  name: z.string().optional(),
  order: z.number().optional(),
});

export type UpdateWorkTradeInput = z.infer<typeof updateWorkTradeSchema>;

export const getWorkTradesSchema = z.object({
  projectId: z.number(),
});

export type GetWorkTradesInput = z.infer<typeof getWorkTradesSchema>;

export const updateWoWorkTradeSchema = z.object({
  workOrderId: z.number(),
  workTradeId: z.number(),
});

export type UpdateWoWorkTradeInput = z.infer<typeof updateWoWorkTradeSchema>;

export const updateWoWorkPrioritySchema = z.object({
  workOrderId: z.number(),
  workPriorityId: z.number(),
});

export type UpdateWoWorkPriorityInput = z.infer<
  typeof updateWoWorkPrioritySchema
>;

export const getOneWorkOrderSchema = z.object({
  workOrderId: z.number(),
});

export type GetOneWorkOrderInput = z.infer<typeof getOneWorkOrderSchema>;

export const createWorkOrderSchema = z.object({
  projectId: z.number(),
  subject: z.string(),
  description: z.string(),
  defectTypeId: z.number(),
  locationId: z.number().optional(),
  assetId: z.number().optional(),
  assignedUsersIds: z.array(z.number()),
  assignedVendorsIds: z.array(z.number()),
  workRequestIds: z.array(z.string()).optional(),
  refNo: z.string().optional(),
  createdOn: z.date().optional(),
  photos: z
    .array(
      z.object({
        name: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({ id: z.number() }),
        underProjectId: z.number(),
        gsPath: z.string(),
        url: z.string(),
      })
    )
    .optional(),
  docs: z
    .array(
      z.object({
        name: z.string(),
        fileSizeMb: z.string(),
        underProject: z.object({ id: z.number() }),
        underProjectId: z.number(),
        gsPath: z.string(),
        url: z.string(),
      })
    )
    .optional(),
  linkWRPhotosIds: z.array(z.number()).optional(),
});

export type CreateWorkOrderInput = z.infer<typeof createWorkOrderSchema>;

// Define the PatchWorkOrderDto schema
export const patchWorkOrderSchema = z.object({
  defectId: z.number(),
  updatedById: z.number(),
  assignedUsers: z.array(z.number()),
  defectStatusId: z.number().optional(),
  type: z.enum(["UPDATE", "CLOSE"]).optional(),
  rating: z.number().optional(),
  closingRemark: z.string().optional(),
  subject: z.string().optional(),
  description: z.string().optional(),
  defectTypeId: z.number().optional(),
  locationId: z.number().optional(),
  assetId: z.number().optional(),
  refNo: z.string().optional(),
  createdOn: z.date().optional(),
  photos: z.array(
    z.object({
      name: z.string(),
      fileSizeMb: z.string(),
      underProject: z.object({ id: z.number() }),
      underProjectId: z.number(),
      gsPath: z.string(),
      url: z.string(),
    })
  ).optional(),
  docs: z.array(
    z.object({
      name: z.string(),
      fileSizeMb: z.string(),
      underProject: z.object({ id: z.number() }),
      underProjectId: z.number(),
      gsPath: z.string(),
      url: z.string(),
    })
  ).optional(),
  workRequestIds: z.array(z.string()).optional(),
});

// Export the type for PatchWorkOrderDto
export type PatchWorkOrderDto = z.infer<typeof patchWorkOrderSchema>;

export const getJkrPataPdfReportSchema = z.object({
  workOrderId: z.number(),
});

export type GetJkrPataPdfReportInput = z.infer<
  typeof getJkrPataPdfReportSchema
>;

export const updateRatingReviewSchema = z.object({
  workOrderId: z.number(),
  rating: z.number().optional().nullable(),
  closingRemark: z.string().optional(),
  signatory: z.object({
    name: z.string(),
    gsPath: z.string(),
    fileSizeMb: z.string(),
    projectId: z.number(),
  }).optional(),
  signedBy: z.string().optional(),
});

export type UpdateRatingReviewInput = z.infer<typeof updateRatingReviewSchema>;

export const deleteVerificationSignatorySchema = z.object({
  workOrderId: z.number(),
});

export type DeleteVerificationSignatoryInput = z.infer<
  typeof deleteVerificationSignatorySchema
>;

export const updateCorrectiveRootCauseSchema = z.object({
  workOrderId: z.number(),
  correctiveAction: z.string().optional(),
  rootCause: z.string().optional(),
});

export type UpdateCorerctiveRootCauseInput = z.infer<
  typeof updateCorrectiveRootCauseSchema
>;

export const getWorkOrderStatusSchema = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetWorkOrderStatusInput = z.infer<typeof getWorkOrderStatusSchema>;

export type WorkOrderStatusCount = {
  id: number;
  name: string;
  colorHex: string | null;
  order: number | null;
  count: number;
};

// Add these new schemas at the end of the file
export const updateKpiValueSchema = z.object({
  projectId: z.number(),
  kpiCode: z.string(),
  value: z.number(),
});

export type UpdateKpiValueInput = z.infer<typeof updateKpiValueSchema>;

export const deleteWorkOrderPhotoSchema = z.object({
  attachmentId: z.number(),
  workOrderId: z.number(),
});

export type DeleteWorkOrderPhotoInput = z.infer<typeof deleteWorkOrderPhotoSchema>;

export const deleteWorkOrderDocSchema = z.object({
  attachmentId: z.number(),
  workOrderId: z.number(),
});

export type DeleteWorkOrderDocInput = z.infer<typeof deleteWorkOrderDocSchema>;

export const deleteWorkOrderSchema = z.object({
  workOrderId: z.string(),
  projectId: z.string(),
});

export type DeleteWorkOrderInput = z.infer<typeof deleteWorkOrderSchema>;

// Add this new schema
export const getWorkOrderCountSchema = z.object({
  projectId: z.string(),
  workStatusId: z.string(),
  plannedDateStartISO: z.string().optional(),
  plannedDateEndISO: z.string().optional(),
  search: z.string().optional(),
  filterAssetTypeIds: z.array(z.string()).optional(),
  filterWorkOrderTypeIds: z.array(z.string()).optional(),
  filterRaisedByIds: z.array(z.string()).optional(),
  locFilterId: z.string().optional(),
  filterStartDateISO: z.string().optional(),
  filterEndDateISO: z.string().optional(),
  filterWorkTradeIds: z.array(z.string()).optional(),
  filterPriorityIds: z.array(z.string()).optional(),
});

export type GetWorkOrderCountInput = z.infer<typeof getWorkOrderCountSchema>;

// Add these new schemas
export const getWorkOrderTypesSchema = z.object({
  projectId: z.string(),
  companyId: z.string().optional(),
  search: z.string().optional(),
});

export type GetWorkOrderTypesInput = z.infer<typeof getWorkOrderTypesSchema>;

export type WorkOrderType = {
  id: number;
  name: string;
  status: string;
};

export const closeAndReviewWorkOrderSchema = z.object({
  workOrderId: z.number(),
  closingRemark: z.string().optional(),
  rating: z.number().optional(),
  projectId: z.number(),
});

export type CloseAndReviewWorkOrderInput = z.infer<typeof closeAndReviewWorkOrderSchema>;

export const updateWorkOrderStatusSchema = z.object({
  defectId: z.number(),
  defectStatusId: z.number(),
  projectId: z.number(),
});

export type UpdateWorkOrderStatusInput = z.infer<typeof updateWorkOrderStatusSchema>;

export const GetUpcomingWorkOrdersInput = z.object({
  projectId: z.string(),
  workOrderStatusId: z.string().optional(),
  search: z.string().optional(),
  locFilterId: z.string().optional(),
  assetTypeIds: z.array(z.string()).optional(),
  filterStartDateISO: z.string().optional(),
  filterEndDateISO: z.string().optional(),
  filterAssetTypeIds: z.array(z.string()).optional(),
  filterRaisedByIds: z.array(z.string()).optional(),
  filterWorkOrderTypeIds: z.array(z.string()).optional(),
  filterWorkTradeIds: z.array(z.string()).optional(),
  filterPriorityIds: z.array(z.string()).optional(),
  locationId: z.string().optional(),
  cursor: z.number().nullish(),
});

export type GetUpcomingWorkOrdersInput = z.infer<typeof GetUpcomingWorkOrdersInput>;

// Add this alongside other work order schemas
export const UpdatePlannedDateInput = z.object({
  workOrderId: z.string(),
  plannedDateISO: z.string().nullable(),
});

export type UpdatePlannedDateInput = z.infer<typeof UpdatePlannedDateInput>;
