import { z } from "zod";

export const getOneUserInput = z.object({ firebaseUserId: z.string() });

export type GetOneUserInput = z.infer<typeof getOneUserInput>;

export const getUsersSchema = z.object({
  projectId: z.number(),
});

export type GetUsersInput = z.infer<typeof getUsersSchema>;