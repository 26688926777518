import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Loader2 } from "lucide-react";
import {
  Cell,
  Label,
  Pie,
  PieChart,
} from "recharts";
import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { useSearchParams } from "react-router-dom";
import { cn } from "../../../../lib/utils";

interface PercentageOfSystemUptimePieChartProps {
  fullScreen?: boolean;
}

export default function PercentageOfSystemUptimePieChart({
  fullScreen,
}: PercentageOfSystemUptimePieChartProps) {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } = trpc.analytics.getSystemUptimePercentage.useQuery(
    {
      projectId: project?.id ?? 0,
      month,
      year,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            {data?.message ||
              "No system uptime data available for the selected period"}
          </div>
        </CardContent>
      </Card>
    );
  }

  // Calculate percentages for display
  const chartDataWithPercentage = data.chartData.map((item) => ({
    ...item,
    percentage: item.value.toFixed(1),
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle>System Uptime Percentage</CardTitle>
        <CardDescription>
          Percentage of system uptime, target at 95%
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className={cn("mx-auto aspect-square max-h-[300px] w-full", {
            "max-h-none aspect-video h-full relative": fullScreen,
          })}
        >
          <PieChart>
            <Pie
              data={chartDataWithPercentage}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              innerRadius={60}
              startAngle={90}
              endAngle={-270}
            >
              {chartDataWithPercentage.map((entry) => (
                <Cell
                  key={entry.name}
                  fill={
                    entry.name === "System Uptime"
                      ? data.chartConfig.uptime.color
                      : data.chartConfig.downtime.color
                  }
                />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-xl font-bold"
                        >
                          {data.averageUptime.toFixed(1)}%
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground text-sm"
                        >
                          Uptime
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
          </PieChart>
        </ChartContainer>
        <div
          className={cn("grid grid-cols-2 gap-4", {
            "mt-6": !fullScreen,
            "absolute bottom-6 left-6 right-6": fullScreen,
          })}
        >
          {chartDataWithPercentage.map((item) => (
            <div
              key={item.name}
              className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm transition-colors hover:bg-accent/10"
            >
              <div className="flex items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-sm"
                  style={{
                    backgroundColor:
                      item.name === "System Uptime"
                        ? data.chartConfig.uptime.color
                        : data.chartConfig.downtime.color
                  }}
                />
                <div className="flex flex-col">
                  <span className="text-xs font-medium text-foreground">
                    {item.name}
                  </span>
                  <span className="text-sm font-semibold">
                    {item.percentage}%
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
