import { TabsList, TabsTrigger, TabsContent } from "../../../../components/ui/tabs";
import LegalComplianceAnalytics from "../LegalComplianceAnalytics/LegalComplianceAnalytics";
import { WorkOrderAnalytics } from "../WorkOrderAnalytics/WorkOrderAnalytics";
import { WorkRequestAnalytics } from "../WorkRequestAnalytics/WorkRequestAnalytics";
import { AdmcPmAnalytic } from "./AdmcPmAnalytic";
import ServiceStandardsAnalytic from "./ServiceStandardsAnalytic";

interface ADMCDashboardProps {
  currentTabIdx: string;
}

export default function ADMCDashboard({ currentTabIdx }: ADMCDashboardProps) {
  return (
    <>
      <TabsList>
        <TabsTrigger value="0">
          <p>Service Standards</p>
        </TabsTrigger>
        <TabsTrigger value="1">
          <p>Work Request</p>
        </TabsTrigger>
        <TabsTrigger value="2">
          <p>Work Order</p>
        </TabsTrigger>
        <TabsTrigger value="3">
          <p>Preventive Maintenance</p>
        </TabsTrigger>
        <TabsTrigger value="4">
          <p>Legal Compliance</p>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="0">
        <ServiceStandardsAnalytic />
      </TabsContent>
      <TabsContent value="1">
        <WorkRequestAnalytics />
      </TabsContent>
      <TabsContent value="2">
        <WorkOrderAnalytics />
      </TabsContent>
      <TabsContent value="3">
        <AdmcPmAnalytic />
      </TabsContent>
      <TabsContent value="4">
        <LegalComplianceAnalytics />
      </TabsContent>
    </>
  );
} 