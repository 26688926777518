import { coerce, z } from "zod";
import {
  AddPmTemplateDtoRepeatType,
  FrequencySettingFrequencySettingType,
} from "../../../api/model";
import { PmTemplateTypeEnum, FrequencySettingType } from "@cerev-cmms/zod-types";

export const PmTemplateFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
  startDate: z.string(),
  assets: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      locationId: z.number(),
    })
  ),
  pmFormat: z.array(
    z.object({
      id: z.string().optional(),
      name: z.string().min(1, "Question is required"),
      pmFormatType: z.object({
        name: z.string(),
        id: z.number().optional(),
      }),
      options: z.array(z.object({
        id: z.number().optional(),
        value: z.string(),
        order: z.number(),
      })).optional(),
    })
  ),
  frequencySetting: z.object({
    frequencySettingType: z.nativeEnum(FrequencySettingType),
    weeklyRepeatDay: z.array(z.string()).optional(),
    monthlyRepeatDay: z.array(z.string()).optional(),
    monthlyRepeatDate: z.array(z.string()).optional(),
    monthlyEveryStepCount: z.number().optional(),
    yearlyRepeatMonth: z.array(z.string()).optional(),
    yearlyRepeatDate: z.array(z.string()).optional(),
    yearlyEveryStepCount: z.number().optional(),
  }),
  pmTemplateType: z.enum(["ONE_EQUIPMENT", "MULTIPLE_EQUIPMENTS"]),
});

export type PmTemplateFormType = z.infer<typeof PmTemplateFormSchema>;
