import { z } from "zod";

// ... (existing types)

export const deletePmAttachmentInputSchema = z.object({
  pmChecklistAttachmentId: z.number(),
  attachmentId: z.number(),
});

export type DeletePmAttachmentInput = z.infer<typeof deletePmAttachmentInputSchema>;

export const deletePmAttachmentOutputSchema = z.object({
  success: z.boolean(),
});

export type DeletePmAttachmentOutput = z.infer<typeof deletePmAttachmentOutputSchema>;

export const getOnePmChecklistInputSchema = z.object({
  pmChecklistId: z.number(),
});

export type GetOnePmChecklistInput = z.infer<typeof getOnePmChecklistInputSchema>;

export const getPmChecklistStatusSchema = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetPmChecklistStatusInput = z.infer<typeof getPmChecklistStatusSchema>;

export type PmChecklistStatusCount = {
  id: number;
  name: string;
  colorHex: string | null;
  order: number | null;
  count: number;
};

export const pmFormatTypeSchema = z.object({
  name: z.string()
});

export const pmFormatSchema = z.object({
  name: z.string(),
  order: z.number(),
  pmFormatType: z.object({
    name: z.string(),
  }),
  options: z.array(z.string()).optional(),
});

export const createPmTemplateInputSchema = z.object({
  name: z.string(),
  createdById: z.number(),
  assetIds: z.array(z.number()),
  pmFormats: z.array(pmFormatSchema),
  dateStartISO: z.string(),
  companyId: z.number(),
  projectId: z.number(),
  frequencySettingType: z.enum(['DAILY', 'WEEKLY', 'BY_MONTH', 'BY_YEAR']),
  weeklyRepeatDay: z.array(z.string()).optional(),
  monthlyRepeatDay: z.array(z.string()).optional(),
  monthlyRepeatDate: z.array(z.string()).optional(),
  monthlyEveryStepCount: z.number().optional(),
  yearlyRepeatMonth: z.array(z.string()).optional(),
  yearlyRepeatDate: z.array(z.string()).optional(),
  yearlyEveryStepCount: z.number().optional(),
  pmTemplateType: z.enum(['ONE_EQUIPMENT', 'MULTIPLE_EQUIPMENTS']).optional().default('ONE_EQUIPMENT'),
});

export type CreatePmTemplateInput = z.infer<typeof createPmTemplateInputSchema>;

export const updatePmFormatOptionSchema = z.object({
  id: z.number().optional(),
  value: z.string(),
  order: z.number(),
});

export const updatePmFormatSchema = z.object({
  id: z.number().optional(),
  name: z.string(),
  order: z.number(),
  pmFormatType: z.object({
    name: z.enum(['BOOL', 'VARCHAR', 'NUMBER', 'ATTACHMENT', 'SINGLE_OPTION', 'MULTI_OPTION']),
  }),
  options: z.array(updatePmFormatOptionSchema).optional(),
});

export const updatePmTemplateInputSchema = z.object({
  pmTemplateId: z.string(),
  name: z.string(),
  updatedById: z.number(),
  companyId: z.number(),
  projectId: z.number(),
  assetIds: z.array(z.number()),
  pmFormats: z.array(updatePmFormatSchema),
  startDate: z.date(),
  dateStartISO: z.string(),
  frequencySettingType: z.enum(['DAILY', 'WEEKLY', 'BY_MONTH', 'BY_YEAR']),
  weeklyRepeatDay: z.array(z.string()).optional(),
  monthlyRepeatDay: z.array(z.string()).optional(),
  monthlyRepeatDate: z.array(z.string()).optional(),
  monthlyEveryStepCount: z.number().optional(),
  yearlyRepeatMonth: z.array(z.string()).optional(),
  yearlyRepeatDate: z.array(z.string()).optional(),
  yearlyEveryStepCount: z.number().optional(),
  pmTemplateType: z.enum(['ONE_EQUIPMENT', 'MULTIPLE_EQUIPMENTS']).optional().default('ONE_EQUIPMENT'),
});

export type UpdatePmTemplateInput = z.infer<typeof updatePmTemplateInputSchema>;

export const getSinglePmTemplateInputSchema = z.object({
  pmTemplateId: z.string(),
});

export type getSinglePmTemplateInput = z.infer<typeof getSinglePmTemplateInputSchema>;

// Define the enum values as const objects first
export const FrequencySettingType = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  BY_MONTH: 'BY_MONTH',
  BY_YEAR: 'BY_YEAR'
} as const;

export const PmFormatType = {
  BOOL: 'BOOL',
  VARCHAR: 'VARCHAR',
  NUMBER: 'NUMBER',
  MULTICHOICES: 'MULTICHOICES',
  ATTACHMENT: 'ATTACHMENT',
  SINGLE_OPTION: 'SINGLE_OPTION',
  MULTI_OPTION: 'MULTI_OPTION'
} as const;

// Create Zod schemas from the const objects
export const FrequencySettingTypeEnum = z.enum([
  'DAILY',
  'WEEKLY',
  'BY_MONTH',
  'BY_YEAR'
]);

export const PmFormatTypeEnum = z.enum([
  'BOOL',
  'VARCHAR',
  'NUMBER',
  'ATTACHMENT',
  'SINGLE_OPTION',
  'MULTI_OPTION'
]);

// Define the enum values as const objects first
export const PmTemplateType = {
  ONE_EQUIPMENT: 'ONE_EQUIPMENT',
  MULTIPLE_EQUIPMENTS: 'MULTIPLE_EQUIPMENTS',
} as const;

// Create Zod schema from the const object
export const PmTemplateTypeEnum = z.enum([
  'ONE_EQUIPMENT',
  'MULTIPLE_EQUIPMENTS',
]) as z.ZodEnum<['ONE_EQUIPMENT', 'MULTIPLE_EQUIPMENTS']>;

// Export types
export type FrequencySettingTypeEnum = z.infer<typeof FrequencySettingTypeEnum>;
export type PmFormatTypeEnum = z.infer<typeof PmFormatTypeEnum>;

export const createPmChecklistInputSchema = z.object({
  pmTemplateId: z.number(),
  projectId: z.number(),
  assetIds: z.array(z.number()),
  startDate: z.date(),
  endDate: z.date(),
});

export type CreatePmChecklistInput = z.infer<typeof createPmChecklistInputSchema>;

export const updatePmChecklistValueSchema = z.object({
  valueId: z.number(),
  pmFormatId: z.number(),
  type: z.string(),
  value: z.union([
    z.string(), 
    z.number(), 
    z.boolean(),
    z.string(), // For single option value (the option's ID as string)
    z.array(z.string()), // For multi option values (array of option IDs as strings)
  ]),
  attachments: z.array(z.object({
    url: z.string(),
    gsPath: z.string(),
    name: z.string(),
    fileSizeMb: z.number(),
    underProject: z.object({
      id: z.number()
    }).optional(),
  })).optional(),
});

export const updatePmChecklistInputSchema = z.object({
  pmChecklistId: z.string(),
  pmTemplateId: z.number(),
  projectId: z.number(),
  pmStatusId: z.number(),
  updatedById: z.number(),
  values: z.array(updatePmChecklistValueSchema),
});

export type UpdatePmChecklistInput = z.infer<typeof updatePmChecklistInputSchema>;

export const getPmTemplatesInputSchema = z.object({
  projectId: z.number(),
  search: z.string().optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
  cursorId: z.string().optional(),
});

export type GetPmTemplatesInput = z.infer<typeof getPmTemplatesInputSchema>;

export const getPmChecklistsInputSchema = z.object({
  projectId: z.string(),
  search: z.string().optional(),
  pmChecklistStatusId: z.string().optional(),
  assetId: z.string().optional(),
  filterStartDateISO: z.string().optional(),
  filterEndDateISO: z.string().optional(),
  assetTypeIds: z.array(z.string()).optional(),
  locFilterId: z.string().optional(),
  plannedDateStartISO: z.string().optional(),
  plannedDateEndISO: z.string().optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
  cursor: z.string().optional(),
});

export type GetPmChecklistsInput = z.infer<typeof getPmChecklistsInputSchema>;

export const deletePmChecklistInputSchema = z.object({
  pmChecklistId: z.number(),
});

export type DeletePmChecklistInput = z.infer<typeof deletePmChecklistInputSchema>;

export const deletePmChecklistOutputSchema = z.object({
  success: z.boolean(),
});

export type DeletePmChecklistOutput = z.infer<typeof deletePmChecklistOutputSchema>;

export const GetLocationWithPmCountInput = z.object({
  projectId: z.string(),
  parentId: z.string(),
  search: z.string().optional(),
  cursorId: z.string().optional(),
  companyId: z.string(),
});

export type GetLocationWithPmCountInput = z.infer<typeof GetLocationWithPmCountInput>;

export const GetAssetWithPmCountInput = z.object({
  projectId: z.string(),
  locationId: z.string(),
  companyId: z.string(),
  search: z.string().optional(),
  cursorId: z.string().optional(),
});

export type GetAssetWithPmCountInput = z.infer<typeof GetAssetWithPmCountInput>;

export const UpdatePmPlannedDateInput = z.object({
  pmChecklistId: z.string(),
  plannedDateISO: z.string().nullable(),
});

export type UpdatePmPlannedDateInput = z.infer<typeof UpdatePmPlannedDateInput>;

export const getUpcomingPmChecklistInputSchema = z.object({
  projectId: z.string(),
  search: z.string().optional(),
  pmChecklistStatusId: z.string().optional(),
  assetId: z.string().optional(),
  filterStartDateISO: z.string().optional(),
  filterEndDateISO: z.string().optional(),
  assetTypeIds: z.array(z.string()).optional(),
  locFilterId: z.string().optional(),
  plannedDateStartISO: z.string().optional(),
  plannedDateEndISO: z.string().optional(),
  page: z.number().optional(),
  rowsPerPage: z.number().optional(),
  cursor: z.number().optional(),
});

export type GetUpcomingPmChecklistInput = z.infer<typeof getUpcomingPmChecklistInputSchema>;

