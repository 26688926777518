import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { useAssetControllerGetFullAssets } from "../../../../api/assets/assets";
import { useAuthControllerGetCurrentUser } from "../../../../api/auth/auth";
import { useDefectTypeControllerGetDefectTypes } from "../../../../api/defect-type/defect-type";
import { useUserControllerGetUsers } from "../../../../api/users/users";
import { useDefectControllerCreateDefect } from "../../../../api/work-orders-defects/work-orders-defects";
import {
  useWorkRequestControllerGetOneWorkRequest,
  useWorkRequestControllerGetWorkRequests,
} from "../../../../api/work-request/work-request";
import useAppStorage from "../../../../hooks/useAppStorage";
import { useGetUserDataQuery } from "../../../../redux/slices/Auth/AuthApi";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import AppButton from "../../../AppButton";
import AppCameraFieldFile from "../../../AppCameraFieldFiles";
import AppTextAreaField from "../../../AppTextAreaField";
import AppTextField from "../../../AppTextField";
import AppSelectWithDialog from "../../../dialogs/AppSelectWithDialog/AppSelectWithDialog";
import { assetColumns } from "../columns/assetColumns";
import { assignedUserColumns } from "../columns/assignedUserColumns";
import { linkWorkRequestColumns } from "../columns/linkWorkRequestColumns";
import { workOrderTypeColumns } from "../columns/workOrderTypeColumns";
import AppDocumentUpload from "../../../AppDocumentUpload";
import { useEffect, useState } from "react";
import WOAttachmentField from "./WOAttachmentField";
import { trpc } from "../../../../lib/trpc"; // Import trpc
import useIsUpdateDrawerState from "../../../../hooks/useDrawerState";
import DrawerFormSkeleton from "../../../skeletons/DrawerFormSkeleton";
import { RouterOutputs } from "@cerev-cmms/trpc";
import AppCameraFieldFilesTRPC from "../../../AppCameraFieldFilesTRPC";
import AppDocumentUploadTRPC from "../../../AppDocumentUploadTRPC";
import { DRAWER_VIEW_STATE, WORK_ORDER_DETAIL_DRAWER } from "../../AppDrawer";

import AdvancedFieldsSection from "./AdvancedFieldsSection";

const BaseWorkOrderFormSchema = z.object({
  workOrderType: z.object(
    {
      id: z.number(),
      name: z.string(),
    },
    {
      required_error: "Please select a work order type",
    }
  ),
  asset: z
    .object({
      id: z.number(),
      name: z.string(),
      locationId: z.number(),
    })
    .optional(),
  subject: z.string().min(1, { message: "Please insert a subject" }),
  description: z.string().min(1, { message: "Please insert a description" }),
  assignedUser: z
    .object({
      id: z.number(),
      name: z.string(),
      vendorId: z.number().nullable(),
    })
    .array()
    .min(1, { message: "Please select a user" }),
  attachments: z.instanceof(File).array(),
  docs: z.instanceof(File).array(),
  linkedWRAttachments: z
    .object({ id: z.number(), url: z.string() })
    .array()
    .optional(),
  workRequests: z
    .object({
      id: z.string(),
    })
    .array()
    .optional(),
  refNo: z.string().optional(),
  createdOn: z.date().optional(),
});

const AttachmentSchemas = z.object({
  linkedWRAttachments: z
    .object({ id: z.number(), url: z.string() })
    .array()
    .optional(),
  attachments: z.instanceof(File).array(),
});
// .refine(
//   (schema) => {
//     if (schema.linkedWRAttachments && schema.linkedWRAttachments.length > 0) {
//       return true;
//     }
//     return schema.attachments ? schema.attachments.length > 0 : false;
//   },
//   {
//     path: ["attachments"],
//     message: "At least one photo is required",
//   }
// );

const WorkOrderFormSchema = z.intersection(
  BaseWorkOrderFormSchema,
  AttachmentSchemas
);

export type WorkOrderForm = z.infer<typeof WorkOrderFormSchema>;
type Attachment =
  RouterOutputs["workOrders"]["getOneWorkOrder"]["photos"][number];

export default function WorkOrderFormDrawer() {
  const utils = trpc.useUtils();
  const isUpdateDrawer = useIsUpdateDrawerState();
  const [searchParams, setSearchParams] = useSearchParams();
  const workRequestId = searchParams.get("workRequestId");
  const workOrderId = searchParams.get("workOrderId");
  const { data: user } = useGetUserDataQuery();
  const activeComp = useAppSelector((state) => state.root.activeCompany);
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const qc = useQueryClient();
  const [uploadedPhotos, setUploadedPhotos] = useState<Attachment[]>([]);
  const [uploadedDocs, setUploadedDocs] = useState<Attachment[]>([]);

  // Keep existing queries
  const { data: workRequest } = useWorkRequestControllerGetOneWorkRequest(
    workRequestId ?? "",
    {
      query: {
        enabled: !!workRequestId,
        select: (res) => res.data,
      },
    }
  );

    // Update to work request title, description and photos if workRequestid exist - Means the create command comes from work request detail screen
    useEffect(() => {
      if (workRequest) {
        methods.setValue("subject", workRequest?.title ?? "");
        methods.setValue("description", workRequest?.description ?? "");
        methods.setValue(
          "linkedWRAttachments",
          workRequest.photos.map((p) => ({ id: p.id, url: p.url }))
        );
        methods.setValue("workRequests", [{ id: workRequest.id }]);
        if (
          workRequest.asset !== undefined &&
          workRequest.asset?.locationId !== undefined
        )
          methods.setValue("asset", {
            id: workRequest.asset.id,
            name: workRequest.asset.name,
            locationId: workRequest.asset.locationId,
          });
      }
    }, [workRequest]);

  const { data: workOrderTypeList } = useDefectTypeControllerGetDefectTypes(
    {
      projectId: activeProj?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeComp && !!activeProj,
      },
    }
  );

  const { data: fullAssetList } = useAssetControllerGetFullAssets(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      query: {
        enabled: !!activeProj,
      },
    }
  );

  const { data: currentUser } = useAuthControllerGetCurrentUser();

  const { data: usersData } = useUserControllerGetUsers(
    {
      projectId: activeProj?.id ?? 0,
      userId: currentUser?.data?.id ?? 0,
      companyId: activeComp?.id ?? 0,
    },
    {
      query: {
        enabled: !!activeProj && !!currentUser && !!activeComp,
      },
    }
  );

  const { data: workRequestList } = trpc.workRequest.getWorkRequests.useQuery(
    {
      projectId: activeProj?.id?.toString() ?? "",
    },
    {
      enabled: !!activeProj,
    }
  );

  // Add query to get work order details for update
  const { data: workOrder, isLoading: workOrderLoading } =
    trpc.workOrders.getOneWorkOrder.useQuery(
      { workOrderId: Number(workOrderId) },
      {
        enabled: !!workOrderId && isUpdateDrawer,
      }
    );

  const { useUploadAttachmentMutation } = useAppStorage();
  const { mutate, isPending } = useUploadAttachmentMutation({
    onSuccessMutate: () => {
      utils.workOrders.invalidate();
    },
  });

  // Add mutation for updating work order
  const { mutateAsync: updateWorkOrder, isPending: updateIsPending } =
    trpc.workOrders.updateWorkOrder.useMutation({
      onSuccess: () => {
        setSearchParams((p) => {
          p.set("drawerType", DRAWER_VIEW_STATE);
          p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
          return p;
        });
      },
    });

  const { mutateAsync: createWorkOrder } =
    trpc.workOrders.createWorkOrder.useMutation({
      onSuccess: (res) => {
        qc.invalidateQueries({
          predicate: (query) => {
            return (
              (query.queryKey[0] as string).includes("work-order") ||
              (query.queryKey[0] as string).includes("work-request") ||
              (query.queryKey[0] as string).includes("defect")
            );
          },
        });
        setSearchParams((p) => {
          p.set("drawerType", DRAWER_VIEW_STATE);
          p.set("drawerType", WORK_ORDER_DETAIL_DRAWER);
          p.set("workOrderId", res.id.toString());
          return p;
        });
      },
    });

  // Add mutation for deleting attachments
  const { mutateAsync: deleteDocument } =
    trpc.workOrders.deleteDoc.useMutation();
  const { mutateAsync: deletePhoto } =
    trpc.workOrders.deletePhoto.useMutation();

  const onDeleteUploadedDoc = async (att: Attachment) => {
    const newUploadedDocs = uploadedDocs.filter((v) => v.id !== att.id);
    if (!workOrder) return;
    deleteDocument({
      attachmentId: att?.id ?? 0,
      workOrderId: workOrder.id,
    });
    setUploadedDocs(newUploadedDocs);
  };

  const onDeleteUploadedPhoto = async (att: Attachment) => {
    const newUploadedPhotos = uploadedPhotos.filter((v) => v.id !== att.id);
    if (!workOrder) return;
    deletePhoto({
      attachmentId: att?.id ?? 0,
      workOrderId: workOrder.id,
    });
    setUploadedPhotos(newUploadedPhotos);
  };

  const methods = useForm<WorkOrderForm>({
    resolver: zodResolver(WorkOrderFormSchema),
    defaultValues: {
      workOrderType: undefined,
      asset: undefined,
      subject: "",
      description: "",
      assignedUser: [],
      attachments: [],
      workRequests: [],
      docs: [],
    },
  });

  // Update form when editing existing work order
  useEffect(() => {
    if (isUpdateDrawer && workOrder) {
      methods.setValue("subject", workOrder?.subject ?? "");
      methods.setValue("description", workOrder?.description ?? "");
      methods.setValue("workOrderType", {
        id: workOrder?.defectType.id,
        name: workOrder?.defectType.name ?? "",
      });
      if (workOrder?.asset) {
        methods.setValue("asset", {
          id: workOrder?.asset.id,
          name: workOrder?.asset.name ?? "",
          locationId: workOrder?.asset.locationId ?? 0,
        });
      }
      methods.setValue(
        "assignedUser",
        workOrder?.assignedUsers.map((user) => ({
          id: user.id,
          name: user.name ?? "",
          vendorId: user.vendor?.id || null,
        }))
      );
      setUploadedPhotos(workOrder.photos ?? []);
      setUploadedDocs(workOrder.docs ?? []);
      methods.setValue(
        "workRequests",
        workOrder.workRequests?.map((wr) => ({
          id: wr.id,
          title: wr.title,
          description: wr.description,
        })) ?? []
      );
      methods.setValue("refNo", workOrder.refNo ?? undefined);
      methods.setValue("createdOn", workOrder.createdOn ?? undefined);
    }
  }, [isUpdateDrawer, workOrder]);

  const onSubmit: SubmitHandler<WorkOrderForm> = async (data) => {
    await mutate({
      files: data.attachments ?? [],
      secondFiles: data.docs ?? [],
      mutateAsync: async (atts, docs) => {
        if (isUpdateDrawer && workOrder) {
          await updateWorkOrder({
            defectId: workOrder.id,
            updatedById: user?.id ?? 0,
            assignedUsers: data.assignedUser.map((u) => u.id),
            subject: data.subject,
            description: data.description,
            defectTypeId: data.workOrderType.id,
            locationId: data.asset?.locationId,
            assetId: data.asset?.id,
            workRequestIds: data.workRequests?.map((wr) => wr.id) ?? [],
            photos: atts?.map((a) => ({
              name: a?.name ?? "",
              fileSizeMb: a?.fileSizeMb ?? "",
              underProject: { id: activeProj?.id! },
              underProjectId: activeProj?.id!,
              gsPath: a?.gsPath ?? "",
              url: a?.url ?? "",
            })),
            docs: docs?.map((a) => ({
              name: a?.name ?? "",
              fileSizeMb: a?.fileSizeMb ?? "",
              underProject: { id: activeProj?.id! },
              underProjectId: activeProj?.id!,
              gsPath: a?.gsPath ?? "",
              url: a?.url ?? "",
            })),
            refNo: data.refNo,
            createdOn: data.createdOn,
          });
        } else {
          await createWorkOrder({
            projectId: activeProj?.id ?? 0,
            subject: data.subject,
            description: data.description,
            defectTypeId: data.workOrderType.id,
            locationId: data.asset?.locationId,
            assetId: data.asset?.id,
            assignedUsersIds: data.assignedUser.map((u) => u.id),
            assignedVendorsIds: data.assignedUser.reduce((acc, curr) => {
              if (curr.vendorId) acc.push(curr.vendorId);
              return acc;
            }, [] as number[]),
            photos: atts?.map((a) => ({
              name: a?.name ?? "",
              fileSizeMb: a?.fileSizeMb ?? "",
              underProject: { id: activeProj?.id! },
              underProjectId: activeProj?.id!,
              gsPath: a?.gsPath ?? "",
              url: a?.url ?? "",
            })),
            docs: docs?.map((a) => ({
              name: a?.name ?? "",
              fileSizeMb: a?.fileSizeMb ?? "",
              underProject: { id: activeProj?.id! },
              underProjectId: activeProj?.id!,
              gsPath: a?.gsPath ?? "",
              url: a?.url ?? "",
            })),
            workRequestIds: data.workRequests?.map((wr) => wr.id) ?? [],
            linkWRPhotosIds: data.linkedWRAttachments?.map((wr) => wr.id) ?? [],
            refNo: data.refNo,
            createdOn: data.createdOn,
          });
        }
      },
    });
  };

  if (workOrderLoading) return <DrawerFormSkeleton />;

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col gap-4">
        <p className="font-sans text-2xl font-bold">
          {isUpdateDrawer ? "Update" : "Create"} Work Order
        </p>
        <AppSelectWithDialog
          columns={workOrderTypeColumns}
          label="Work Order Type"
          placeholder="Select Work Order Type"
          defaultValue={undefined}
          control={methods.control}
          name="workOrderType"
          items={workOrderTypeList?.data ?? []}
          onResultRender={(item, idx) => {
            return <div key={idx}>{item?.name}</div>;
          }}
          onOptionsRender={(item, idx) => <div key={idx}>{item?.name}</div>}
          dialogTitle="Select Work Order Type"
          error={!!methods.formState.errors.workOrderType?.message}
          helperText={methods.formState.errors.workOrderType?.message}
        />
        <AppSelectWithDialog
          label="Equipment"
          placeholder="Select an Equipment"
          columns={assetColumns}
          defaultValue={undefined}
          control={methods.control}
          name="asset"
          items={fullAssetList?.data ?? []}
          onResultRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">
                {item?.assetType?.name ?? "-"}
              </div>
            </div>
          )}
          onOptionsRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">
                {item?.assetType?.name ?? "-"}
              </div>
            </div>
          )}
          dialogTitle="Select an Equipment"
          error={!!methods.formState.errors.asset?.message}
          helperText={methods.formState.errors.asset?.message}
        />
        <AppTextField label="Subject" name="subject" />
        <AppTextAreaField label="Description" name="description" />
        <AppSelectWithDialog
          columns={assignedUserColumns}
          label="Assign To"
          placeholder="Select Users"
          defaultValue={[]}
          control={methods.control}
          name="assignedUser"
          multiple
          items={usersData?.data ?? []}
          onResultRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">{item?.position ?? "-"}</div>
            </div>
          )}
          onOptionsRender={(item, idx) => (
            <div key={idx} className="flex flex-col">
              <div className="font-medium">{item?.name}</div>
              <div className="font-thin mt-1">{item?.position ?? "-"}</div>
            </div>
          )}
          dialogTitle="Select Users"
          error={!!methods.formState.errors.assignedUser?.message}
          helperText={methods.formState.errors.assignedUser?.message}
        />
        <WOAttachmentField />
        <Controller
          control={methods.control}
          name="docs"
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <AppDocumentUploadTRPC
                uploadedDocs={uploadedDocs}
                onDeleteUploadedDoc={onDeleteUploadedDoc}
                label="Documents"
                onChange={onChange}
                onDelete={(url) => {
                  if (!value) return;
                  const newFiles = value.filter((v) => v !== url);
                  onChange(newFiles);
                }}
                files={value ?? []}
                error={!!error}
                helperText={
                  methods.formState.errors.docs?.message !== ""
                    ? methods.formState.errors.docs?.message
                    : ""
                }
              />
            );
          }}
        />
        {isUpdateDrawer ? (
          <div className="bg-slate-50 border border-slate-200 rounded-lg p-4 shadow-sm">
            <div className="flex items-center gap-2">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 text-slate-500" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" 
                  clipRule="evenodd" 
                />
              </svg>
              <div className="flex flex-col">
                <span className="text-sm font-medium text-slate-700">
                  Work Request Updates
                </span>
                <span className="text-sm text-slate-500">
                  To update linked work requests, please navigate to the Others tab in the work order details.
                </span>
              </div>
            </div>
          </div>
        ) : (
          <AppSelectWithDialog
            multiple
            columns={linkWorkRequestColumns}
            label="Work Requests"
            placeholder="Link Work Requests"
            defaultValue={[]}
            control={methods.control}
            name="workRequests"
            items={workRequestList ?? []}
            onResultRender={(item, idx) => (
              <div className="flex flex-col">
                <div className="font-medium">{item?.title}</div>
                <div className="font-thin mt-1 truncate">
                  {item?.description}
                </div>
              </div>
            )}
            onOptionsRender={(item, idx) => (
              <div className="flex flex-col">
                <div className="font-medium">{item?.title}</div>
                <div className="font-thin mt-1 truncate">
                  {item?.description}
                </div>
              </div>
            )}
            dialogTitle="Select Work Requests"
            error={!!methods.formState.errors.workRequests?.message}
            helperText={methods.formState.errors.workRequests?.message}
          />
        )}
        <AdvancedFieldsSection />
        <div className="flex">
          <AppButton
            label={isUpdateDrawer ? "Update" : "Create"}
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isPending || updateIsPending}
          />
        </div>
      </div>
    </FormProvider>
  );
}
