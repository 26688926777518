import useCurrentUser from "../../../../hooks/useCurrentUser/useCurrentUser";
import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import AppTextField from "../../../AppTextField";
import { AppDateTimePickerForm } from "../../../datetimePicker/AppDateTimePickerForm";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../../ui/accordion";

export default function AdvancedFieldsSection() {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const user = useCurrentUser();

  // Add policy check for advanced fields
  const { data: advancedFieldsPolicy } =
    trpc.policies.checkOneUserPolicy.useQuery(
      {
        projectId: activeProj?.id?.toString() ?? "",
        policyName: "AMEND_ADVANCE_FIELDS_WORK_ORDER",
        userId: user?.data?.id ?? 0,
      },
      {
        enabled: !!activeProj && !!user,
      }
    );

  const canEditAdvancedFields =
    advancedFieldsPolicy?.data === "ALLOWED";

  if (!canEditAdvancedFields) return null;

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="advanced-fields">
        <AccordionTrigger className="hover:no-underline">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-slate-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"
              />
            </svg>
            <span>Advanced Fields</span>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col gap-4 pt-2 px-1">
            <AppTextField
              label="Reference Number"
              name="refNo"
              placeholder="Enter reference number"
            />
            <AppDateTimePickerForm
              label="Created Date"
              name="createdOn"
              showTime={false}
            />
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
