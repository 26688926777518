import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import { useState } from "react";
import {
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTable } from "../../components/react-table/DataTable";
import useDebounce from "../../hooks/useDebounce";
import { pmSettingColumns } from "./columns/PmSettingColumns";
import AppButton from "../../components/AppButton";
import { useSearchParams } from "react-router-dom";
import {
  DRAWER_CREATE_STATE,
  PM_TEMPLATE_FORM_DRAWER,
} from "../../components/drawers/AppDrawer";
import AppTextField from "../../components/AppTextField";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import { CerevModule } from "../../api/model";
import { trpc } from "../../lib/trpc";

export default function PmTemplateScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProject = useAppSelector((state) => state.root.activeProject);
  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: "",
    },
  });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const search = useWatch({ control: methods.control, name: "search" });
  const debouncedQuery = useDebounce({
    value: {
      projectId: activeProject?.id ?? 0,
      search,
      page: pagination.pageIndex,
      rowsPerPage: pagination.pageSize,
    },
    delay: 500,
  });

  const {
    data: pmTemplateData,
    isLoading: pmTemplateIsLoading,
    isFetching: pmTemplateIsFetching,
  } = trpc.pm.getPmTemplates.useQuery(debouncedQuery, {
    enabled: !!activeProject,
  });

  const table = useReactTable({
    data: pmTemplateData?.data ?? [],
    columns: pmSettingColumns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    pageCount: pmTemplateData?.totalCount
      ? Math.ceil(pmTemplateData.totalCount / pagination.pageSize)
      : 0,
    state: {
      columnVisibility: {
        id: false,
        assets: false,
      },
    },
  });

  return (
    <ModuleAccessGuard module={CerevModule.PM}>
      <div className="flex flex-col">
        <div className="flex gap-2">
          <AppTitle title="PM Template" />
          <AppButton
            label="+ Add Template"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", PM_TEMPLATE_FORM_DRAWER);
                p.set("drawerState", DRAWER_CREATE_STATE);
                return p;
              });
            }}
          />
        </div>
        <FormProvider {...methods}>
          <AppTextField
            name="search"
            className="w-[400px] mb-1"
            inputClassName="bg-transparent"
            placeholder="Search Template..."
          />
          <DataTable
            isFetching={pmTemplateIsFetching}
            table={table}
            isLoading={pmTemplateIsLoading}
            totalCount={pmTemplateData?.totalCount ?? 0}
            rowsPerPage={pagination.pageSize}
            pageIdx={pagination.pageIndex}
          />
        </FormProvider>
      </div>
    </ModuleAccessGuard>
  );
}
