import { useSearchParams } from "react-router-dom";
import useDate from "../../../hooks/useDate";
import { trpc } from "../../../lib/trpc";
import AppItemInfo from "../../AppItemInfo";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import {
  Calendar,
  CalendarClock,
  Hash,
  Pencil,
} from "lucide-react";
import AppButton from "../../AppButton";
import { DRAWER_UPDATE_STATE, PM_CHECKLIST_FORM_DRAWER } from "../AppDrawer";
import PmChecklistEquipmentInfo from "../../pm/PmChecklistEquipmentInfo";
import DeletePmChecklistButton from "./components/DeletePmChecklistButton";
import { PlannedDateField } from "./components/PlannedDateField";
import PmFormatField, { PmFormatType } from "./components/PmFormatField";

export default function PmChecklistDetailDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pmChecklistId = searchParams.get("pmChecklistId");
  const { formatDateToLocal, diffInDays } = useDate();

  const { data: pmChecklist, isLoading } = trpc.pm.getOnePmChecklist.useQuery(
    {
      pmChecklistId: Number(pmChecklistId),
    },
    {
      enabled: !!pmChecklistId,
    }
  );

  const pmFormats = pmChecklist?.pmTemplate.pmFormat.sort((a, b) => a.order - b.order) ?? [];

  if (isLoading) return <DrawerFormSkeleton />;

  const getFormatValue = (format: any) => {
    switch (format.pmFormatType.name) {
      case "VARCHAR":
        return pmChecklist?.pmChecklistText?.find(
          (v) => v.pmFormatId === format.id
        )?.value;
      case "NUMBER":
        return pmChecklist?.pmChecklistNumber?.find(
          (v) => v.pmFormatId === format.id
        )?.value;
      case "BOOL":
        return pmChecklist?.pmChecklistBool?.find(
          (v) => v.pmFormatId === format.id
        )?.value;
      case "ATTACHMENT":
        return pmChecklist?.pmChecklistAttachment?.find(
          (v) => v.pmFormatId === format.id
        )?.attachments;
      case "SINGLE_OPTION":
        return pmChecklist?.singleOptionValues?.find(
          (v) => v.pmFormatId === format.id
        )?.selectedOption;
      case "MULTI_OPTION":
        return pmChecklist?.multiOptionValues?.find(
          (v) => v.pmFormatId === format.id
        )?.selectedOptions;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-4 items-center">
        <h1 className="font-sans text-2xl font-bold">
          {pmChecklist?.pmTemplate.name ?? "PM Checklist"}
        </h1>
        <div className="flex gap-2">
          <AppButton
            label="Update"
            icon={<Pencil />}
            variant="outline"
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", PM_CHECKLIST_FORM_DRAWER);
                p.set("drawerState", DRAWER_UPDATE_STATE);
                p.set("pmChecklistId", pmChecklistId ?? "");
                return p;
              });
            }}
          />
          <DeletePmChecklistButton pmChecklistId={Number(pmChecklistId)} />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-gray-400">Status</p>
        <div className="flex">
          <div
            className="rounded-md py-2 px-10 mb-2 text-white font-bold"
            style={{ backgroundColor: `#${pmChecklist?.pmStatus?.colorHex}` }}
          >
            {pmChecklist?.pmStatus?.name}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <PmChecklistEquipmentInfo
            isMultipleEquipments={pmChecklist?.pmTemplate.pmTemplateType === 'MULTIPLE_EQUIPMENTS'}
            asset={pmChecklist?.asset}
            multipleAssets={pmChecklist?.multipleAssets}
          />
          <AppItemInfo
            label="Ref No"
            icon={<Hash className="text-primary-900" />}
            content={<p>{pmChecklist?.refNo ?? "-"}</p>}
          />
          <AppItemInfo
            label="Created"
            icon={<Calendar className="text-primary-900" />}
            content={
              <p>
                {formatDateToLocal({
                  dateStr: pmChecklist?.createdOn.toISOString() ?? "",
                  formatting: "d MMM yyyy",
                })}
              </p>
            }
          />
          <AppItemInfo
            label="Planned Date"
            icon={<Calendar className="text-primary-900" />}
            content={
              <PlannedDateField 
                pmChecklistId={pmChecklistId ?? ""}
                disabled={pmChecklist?.pmStatus?.name === "Close"}
              />
            }
          />
          <AppItemInfo
            label="Aging Days"
            icon={<CalendarClock className="text-primary-900" />}
            content={
              <p>
                {pmChecklist?.completedOn
                  ? diffInDays({
                      startDateISO: pmChecklist?.createdOn.toISOString(),
                      endDateISO: pmChecklist?.completedOn.toISOString(),
                    })
                  : "-"}
              </p>
            }
          />
        </div>
        <p className="font-sans text-xl font-bold my-4">Checklist</p>
        <div className="flex flex-col gap-4">
          {pmFormats.map((format) => (
            <PmFormatField
              key={format.id}
              type={format.pmFormatType.name as PmFormatType}
              name={format.name}
              value={getFormatValue(format)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
